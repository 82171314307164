import React from 'react'
import './LetterShareModal.css'
import Clipboard from 'react-clipboard.js';


function LetterShareModal(props) {
    return(
        <div className="modal-overlay">
            <div className="modal-content modal-share">
                <div className="modal-header">
                    <i onClick={props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="modal-box">
                    <h2 className="modal-title">Copy & Share Via Text or Email:</h2>
                    <div id="copy-content">
                        <p className="modal-info">Check out the Marketing 360® Plans & Pricing I created!</p>

                        <p className="modal-info">Click Here: <a id="foo" href={`https://planbuilder.marketing360.com/plans?plan=${props.planId}`}>
                            {`https://planbuilder.marketing360.com/plans?plan=${props.planId}`}
                        </a></p>
                        <p>
                            <Clipboard className="btn btn-primary btn-sm" data-clipboard-target="#foo" onSuccess={props.onCopySuccess}>
                                {props.isCopied ? <span>Copied!</span> : <span>Copy</span>}
                            </Clipboard>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default LetterShareModal
