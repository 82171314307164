
import React, {Component} from 'react'
import './PhoneModalComponent.css'

import {resendCodeByMobilePhone, updateMobilePhoneByLeadId } from '../helpers/apiHelper'

class CheckModalComponent extends Component {

    constructor(props){
        super(props)

        this.state = {
            editable: false,
            errorMessage: null,
            changedNumber: false
        }

        this.handleClick = this.handleClick.bind(this)
        this.handleChangePhoneClick = this.handleChangePhoneClick.bind(this)
        this.toggleEdit = this.toggleEdit.bind(this)
    }

    toggleEdit(){
        this.setState({
            editable: !this.state.editable
        })
    }

    handleChangePhoneClick(){
        let that = this
        updateMobilePhoneByLeadId(this.props.api, this.props.user.id, this.props.user.mobilePhone, function (err, response) {
            if(err){
                //set error message and push to request page
                console.log('[checkModalComponent] error handling number')
                that.setState({
                    errorMessage: 'We could not update your number. Please try again or create a new plan request.'
                })
            }else{
                // todo: render resend button
                console.log('res: ', response)
                that.setState({
                    changedNumber: true
                })
            }
        })
    }

    handleClick(){
        // this.props.resendText()

        let that = this

        resendCodeByMobilePhone(that.props.api, that.props.user.mobilePhone, function(err, response){
            if(err){
                //set error message and push to request page
                console.log('[checkModalComponent] handle error')
                // that.props.history.push('/')
                that.setState({
                    errorMessage: 'We could not find that number in our records. Please try again or create a new plan request.'
                })
            }else{
                // push to login page
                console.log('[checkModalComponent] success on resend, response: ', response)
                that.props.toggleModal()
                that.props.history.push('/login')
            }
        })

    }

    render() {

        let resendButton = null
        let changePhoneButton = null
        let doneText = 'Cancel'
        let editText = 'Edit'
        let currentNumberText = 'The mobile number you used was'
        let changedNumberText = 'Mobile phone successfully changed'
        let mobilePhoneSection = this.props.user.mobilePhone
        let {errorMessage} = this.state

        let editButton = <button onClick={this.toggleEdit} className="btn btn-secondary">
            {this.state.editable ? doneText : editText }
        </button>

        if(this.state.editable){
            mobilePhoneSection = <input value={this.props.user.mobilePhone} onChange={this.props.handleChange}
                                        name='mobilePhone' className="form-control bottom20" type="tel" required/>
            changePhoneButton = <button onClick={this.handleChangePhoneClick} className="btn btn-primary">
                Change Number
            </button>
        }

        if(this.state.changedNumber){
            mobilePhoneSection = this.props.user.mobilePhone
            changePhoneButton = null
            editButton = null
            resendButton = <button onClick={this.handleClick} className="btn btn-primary">
                Resend Access Code
            </button>
        }


        return (
            <div className="modal-overlay">
                <div className="modal-header">
                    <i onClick={this.props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="modal-content">
                    <p className="text-danger">{ errorMessage }</p>
                    <h2 className="modal-title bottom20">{ this.state.changedNumber ? changedNumberText : currentNumberText }</h2>
                    <div className="box-color modal-box">
                        <p className="modal-info">{ mobilePhoneSection }</p>
                        <div className="button-row">
                            { resendButton }
                            { changePhoneButton }
                            { editButton }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default CheckModalComponent
