import React, { Component } from 'react'
import PhoneModalComponent from './modals/PhoneModalComponent'

class CallUs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phoneModalIsActive: false
    }

    this.togglePhoneModal = this.togglePhoneModal.bind(this)
  }

  togglePhoneModal() {
    this.setState({phoneModalIsActive: !this.state.phoneModalIsActive})
  }

  render() {
    return (<CallUsPresenter marketingConsultant={this.props.marketingConsultant} phoneModalIsActive={this.state.phoneModalIsActive} togglePhoneModal={this.togglePhoneModal} user={this.props.user}/>)
  }
}
const CallUsPresenter = props => {
  let modal = <PhoneModalComponent key="phone-modal"
                toggleModal={props.togglePhoneModal}
                user={props.user}
                marketingConsultant={props.marketingConsultant}/>

  return (
    <div className="top20 flex bottom10">
      {props.phoneModalIsActive ? modal : ''}
      <div className="m-auto align-items-center justify-content-center">
        Questions?
        <a className="left10 text-link text-white" onClick={props.togglePhoneModal}>
          Call Us
        </a>
      </div>
    </div>
  )
}

export default CallUs
