import React from 'react'
import './PhoneModalComponent.css'
import MCProfileComponent from '../MCProfileComponent.js'
import OperationHours from '../subComponents/OperationHours'

function PhoneModalComponent(props) {


    let modalContent = <div className="modal-content">
                          <h2 className="modal-title">Call:</h2>
                          <a href="tel:833-277-1327" className="modal-phone">833-277-1327</a>
                          <OperationHours />
                       </div>

      if(props.marketingConsultant.mcAssigned && props.marketingConsultant.status === 'Active' && props.marketingConsultant.firstname && props.marketingConsultant.imageurl){
          modalContent = <div className="modal-content">
          <MCProfileComponent marketingConsultant={props.marketingConsultant} type="short" />
            <OperationHours />
          </div>
      }


    return(
        <div id="PhoneModalComponent" className="modal-overlay">
            <div className="modal-header">
                <i onClick={props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
            </div>
              {modalContent}
        </div>
    )
}


export default PhoneModalComponent
