import React from 'react'
import './HeaderModalComponent.css'
import { Link, withRouter } from 'react-router-dom'

import { menuLinks } from '../helpers/menuLinks'

function HeaderModalComponent(props) {

    let dynamicClass = `HeaderModalComponent modal-overlay`

    // console.log(props)

    const linksRender = menuLinks.map((link) => {

        let icon = null;
        let img = null;
        let liClass = '';

        if (link.hasIcon) {
            icon = <i className={link.iconClass}></i>
        }

        if (link.hasImg) {
            img = <img src={link.imgSrc} alt="" />
        }

        if (link.isStep) {
            liClass = 'main-nav__step';
        }

        if (link.isSubnav) {
            liClass = 'main-nav__subnav';
        }

        if (link.step > props.furthestStep ) {
            liClass = liClass + ' is-disabled'
        }

        return (
            <li key={link.step} className={liClass}>
                <Link onClick={props.toggleModal} to={{ pathname: link.linkTo, search: props.location.search}}>
                    {icon}
                    {img}
                    {link.label}
                </Link>
            </li>
        )
    })

    return(
        <div className={dynamicClass}>
            <div className="modal-content justify-content-start">
                <div className="modal-header">
                    <i onClick={props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>

                <ul className="main-nav">

                    {linksRender}

                </ul>

            </div>
        </div>
    )
}


export default withRouter(HeaderModalComponent)



