import React, { Component } from 'react'
import './HeaderInit.css'

class HeaderInit extends Component {

    render() {

        return (
            <div className="HeaderInit header">
               <p id="header-title">Instantly Build Your Plans & Pricing</p>
               <p className="header-text">(Plans starting at $0/mo)</p>
            </div>
        )
    }
}

export default HeaderInit
