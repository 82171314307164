import React from 'react'
import {numberWithCommas} from '../helpers/Funcs'

const PlanTotalRow = (props) => {

return(
    <div>
        <h3 className="bottom0 plan-row-title">Plan Totals</h3>
        <p className="text-md bottom20">(add or remove items above to adjust price)</p>

        <div className="plan-totals">
            <div className="box-color">
                <h4 className="plan-totals-title">GOOD</h4>
                <h4 className="plan-totals-price bottom0">${numberWithCommas(props.planTotals.good)}</h4>
                <p className="bottom0">/month</p>
            </div>
            <div className="box-color">
                <h4 className="plan-totals-title">BETTER</h4>
                <h4 className="plan-totals-price bottom0">${numberWithCommas(props.planTotals.better)}</h4>
                <p className="bottom0">/month</p>
            </div>
            <div className="box-color">
                <h4 className="plan-totals-title">BEST</h4>
                <h4 className="plan-totals-price bottom0">${numberWithCommas(props.planTotals.best)}</h4>
                <p className="bottom0">/month</p>
            </div>
        </div>
    </div>
)
}

export default PlanTotalRow;