import React from 'react'
import './ModalComponentVideo.css'


function ModalComponentVideo(props) {
    return(
        <div className="modal-video-overlay">
            <div className="modal-video-content">
                <div className="modal-header">
                    <i onClick={props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="modal-video">
                    <iframe title="modal-video" width="560" height="315" src={props.videoUrl} frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )

}


export default ModalComponentVideo
