import {cdnRoute} from './apiHelper'

let meCreative = `${cdnRoute}/ME-Creative.png`
let uxi = `${cdnRoute}/UXi.png`
let em3 = `${cdnRoute}/EM3.png`
let sms = `${cdnRoute}/SMS.png`
let lla = `${cdnRoute}/LLA.png`
let analytics = `${cdnRoute}/Analytics.png`
let trl = `${cdnRoute}/TRL.png`
let nla = `${cdnRoute}/NLA.png`
let smm = `${cdnRoute}/SMM.png`
let sta = `${cdnRoute}/STA.png`
let tpa = `${cdnRoute}/TPA.png`
let ret = `${cdnRoute}/RET.png`

export const menuLinks = [
    {
        label: 'Welcome',
        linkTo: '/category',
        step: 1,
        hasIcon: true,
        iconClass: 'fa fa-home fa-fw',
        hasImg: false,
        imgSrc: null,
        isStep: false,
        isSubnav: false
    }, {
        label: 'How it Works',
        linkTo: '/get-started',
        step: 2,
        hasIcon: true,
        iconClass: 'fa fa-list-ul fa-fw',
        hasImg: false,
        imgSrc: null,
        isStep: false,
        isSubnav: false
    }, {
        label: 'Step 1: CRM Software',
        linkTo: '/crm',
        step: 3,
        hasIcon: false,
        iconClass: null,
        hasImg: false,
        imgSrc: null,
        isStep: true,
        isSubnav: false
    }, {
        label: 'CRM Software',
        linkTo: '/crm-selection',
        step: 4,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: meCreative,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Step 2: Base Platform',
        linkTo: '/base-platform',
        step: 5,
        hasIcon: false,
        iconClass: null,
        hasImg: false,
        imgSrc: null,
        isStep: true,
        isSubnav: false
    }, {
        label: 'Dedicated Marketing Executive',
        linkTo: '/marketing-executive',
        step: 6,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: meCreative,
        isStep: false,
        isSubnav: true
    }, {
        label: 'On-Demand Creative Team/Services',
        linkTo: '/creative-team',
        step: 7,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: meCreative,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Website Design',
        linkTo: '/website-design',
        step: 8,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: uxi,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Email Marketing 360',
        linkTo: '/email-marketing',
        step: 9,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: em3,
        isStep: false,
        isSubnav: true
    }, {
        label: 'SMS Marketing 360',
        linkTo: '/sms-marketing',
        step: 10,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: sms,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Local Listing Ads',
        linkTo: '/business-listing-management',
        step: 11,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: lla,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Analytics & Other Tools',
        linkTo: '/analytics-tools',
        step: 12,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: analytics,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Step 3: Marketing Fuel',
        linkTo: '/marketing-fuel',
        step: 13,
        hasIcon: false,
        iconClass: null,
        hasImg: false,
        imgSrc: null,
        isStep: true,
        isSubnav: false
    }, {
        label: 'Reputation Management',
        linkTo: '/reputation-management',
        step: 14,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: trl,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Natural Listing Ads',
        linkTo: '/natural-listing-ads',
        step: 15,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: nla,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Social Media Management',
        linkTo: '/social-media-management',
        step: 16,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: smm,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Social Targeting Ads',
        linkTo: '/social-targeting-ads',
        step: 17,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: sta,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Top Placement Ads',
        linkTo: '/top-placement-ads',
        step: 18,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: tpa,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Retargeting Ads',
        linkTo: '/retargeting-ads',
        step: 19,
        hasIcon: false,
        iconClass: null,
        hasImg: true,
        imgSrc: ret,
        isStep: false,
        isSubnav: true
    }, {
        label: 'Review Your Plans & Pricing',
        linkTo: '/plan-review',
        step: 20,
        hasIcon: false,
        iconClass: null,
        hasImg: false,
        imgSrc: null,
        isStep: false,
        isSubnav: false
    }
]