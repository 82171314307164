import React from 'react'

function OperationHours(props){

  return(
    <div className="modal-box">
            <p className="modal-info">Our hours by time zone:</p>
            <p className="modal-desc">*Open monday through Friday</p>
            <ul className="time-list">
                <li>Eastern: 9am-6pm</li>
                <li>Central: 8am-5pm</li>
                <li>Mountain: 7am-4pm</li>
                <li>Pacific: 6am-3pm</li>
            </ul>
            <p>Main Line: 833-277-1327</p>
      </div>
  )
}

export default OperationHours
