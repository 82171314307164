import React from 'react'
import './LetterJumbo.css'
import MCDescription from './subComponents/MCDescription.js'
import { formatPhoneNumber } from './helpers/Funcs'

function LetterJumbo(props) {

    let firstName = props.user.fullName
    firstName = firstName.split(" ")

    let title = `Hi,`
    let phoneContent = null

    if (firstName[0] !== "defaultFullName") {
        title = `Hi, ${firstName[0]}`
    }

    phoneContent = <div>
        <p className="margin-bottom-0">Call us at:</p>
        <p className="margin-bottom-0"><a className="phone-link contact-link" href="tel:833-277-1327">833-277-1327</a></p>
        <small className="timeZone">*Open: Monday-Friday, 9am-6pm ET, 8am-5pm CT, 7am-4pm MT, 6am-3pm PT</small>
    </div>

    return (
        <div className="jumbo">
            <h3>{title}</h3>
            <h4>Thank you for requesting plans &amp; pricing.</h4>
            <p>See below for detailed information on the 3 main plans we offer and their pricing.
                Don't hesitate to contact us with any questions you might have along the way.
        </p>
            {phoneContent}
        </div>
    )

}

export default LetterJumbo
