import React, { Component } from 'react'
import './PlansPricing.css'
import { BrowserRouter as Router, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import createBrowserHistory from 'history/createBrowserHistory'
import _ from 'lodash'
import LoginPrompt from './LoginPrompt'
import Login from './Login'
import LetterComponent from './LetterComponent'
import { getUser, getPlan, resendCodeByMobilePhone, postUpdate } from './helpers/apiHelper'
import InitialRequestComponent from './InitialRequestComponent'
import { parseName, formatMarketingConsultant } from './helpers/Funcs'
import { parseV1PlanObject } from './helpers/parsePlanData'

const history = createBrowserHistory()

const socketIOClient = require('socket.io-client');
const sailsIOClient = require('sails.io.js');
const io = sailsIOClient(socketIOClient);
io.sails.useCORSRouteToGetCookie = false
io.sails.autoConnect = false

/* new async */
function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;
    state = {
      Component: AsyncComponent.Component
    }

    componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component
          this.setState({ Component })
        })
      }
    }
    render() {
      const { Component } = this.state
      if (Component) {
        return <Component {...this.props} />
      }
      return null
    }
  }
}

const MainComponent = asyncComponent(() => import('./MainLayoutComponent').then(module => module.default))

/* new async */

class PlansPricing extends Component {

  constructor(props) {
    super(props)

    // console.log('environment: ', process.env.REACT_APP_ENV_MODE)
    let planApi = '';
    if (process.env.REACT_APP_ENV_MODE === 'stage') {
      planApi = 'https://api.planbuilder.madwire.network'
      // planApi = 'http://0.0.0.0:33065'
    } else {
      planApi = 'https://api.planbuilder.marketing360.com'
    }

    this.state = {
      user: {
        id: 'defaultId',
        fullName: 'defaultFullName',
        firstName: '',
        lastName: 'defaultLastName',
        company: 'your company',
        website: 'defaultWebsite',
        email: 'defaultEmail',
        mobilePhone: 'defaultMobilePhone',
        businessCategory: 'defaultCategory',
        employeeNumber: 'defaultEmployeeNumber'
      },
      marketingConsultant: {
        mcAssigned: false,
        firstName: '',
        lastName: '',
        fullName: '',
        email: '',
        extension: 0,
        textnumber: '',
        calendyurl: '',
        status: '',
        imageurl: ''
      },
      api: planApi,
      sessionExists: false,
      planDetails: {
        id: '',
        planIdOne: '',
        planIdTwo: '',
        currentStep: 0,
        furthestStep: 0,
        totalSteps: 20,
        crm: false,
        base: false,
        businessCategory: '',
        employeeNumber: '',
        yearsInBusiness: '',
        marketingArea: 'default',
        customPricingAvailable: false,
        me: false,
        creative: false,
        uxi: false,
        em3: false,
        sms: false,
        lla: false,
        analytics: false,
        trl: false,
        nla: false,
        smm: false,
        sta: false,
        tpa: false,
        ret: false,
        planTotals: {
          good: 0,
          better: 0,
          best: 0
        },
        hasMarketingFuel: false,
        leads: ' leads',
        marketingGoals: {
          leads: false,
          customers: false,
          clients: false,
          orders: false,
          patients: false,
          accounts: false,
          residents: false,
          members: false,
          students: false,
          users: false,
          applications: false,
          referrals: false,
          followers: false
        },
        version: 3
      },
      pricing: {}
    }

    //simple state updates
    this.setStep = this.setStep.bind(this)
    this.updateBusinessCategory = this.updateBusinessCategory.bind(this)
    this.updateEmployeeNumber = this.updateEmployeeNumber.bind(this)
    this.updateYearsInBusiness = this.updateYearsInBusiness.bind(this)
    this.updateMarketingArea = this.updateMarketingArea.bind(this)
    this.updateMarketingGoals = this.updateMarketingGoals.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.handlePlanUpdate = this.handlePlanUpdate.bind(this)
    this.updatePlanId = this.updatePlanId.bind(this)
    this.updateCustomPrice = this.updateCustomPrice.bind(this)
    this.updateSessionStatus = this.updateSessionStatus.bind(this)
    this.updateMC = this.updateMC.bind(this)

    //update entire state objects
    this.updateUserObject = this.updateUserObject.bind(this)
    this.updatePlanObject = this.updatePlanObject.bind(this)
    this.updateUserAndPlanObject = this.updateUserAndPlanObject.bind(this)
    this.updateUserAndMCObject = this.updateUserAndMCObject.bind(this)
    this.updateUserMCAndPlanObject = this.updateUserMCAndPlanObject.bind(this)
    this.updatePricingObject = this.updatePricingObject.bind(this)

    //helpers
    this.calculateLeads = this.calculateLeads.bind(this)
    this.getQueryString = this.getQueryString.bind(this)
    this.setPlandIdCookie = this.setPlandIdCookie.bind(this)
    this.setLeadIdCookie = this.setLeadIdCookie.bind(this)

    //api updates
    this.resendText = this.resendText.bind(this)
    this.setStateWithLeadId = this.setStateWithLeadId.bind(this)
    this.setStateWithPlanId = this.setStateWithPlanId.bind(this)

  }

  //simple state updates
  setStep(currentStep) {
    let nextState = {
      ...this.state.planDetails
    }

    nextState.currentStep = currentStep

    if (nextState.currentStep >= nextState.furthestStep) {
      nextState.furthestStep = currentStep
    }

    this.setState({ planDetails: nextState })
  }

  //update the state plan id from an input field
  updatePlanId(event, part) {
    // console.log('updating plan id')
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;

    if (part === 'partTwo') {
      nextState.planDetails.planIdTwo = value
    } else {
      nextState.planDetails.planIdOne = value
    }

    nextState.planDetails.id = nextState.planDetails.planIdOne + '-' + nextState.planDetails.planIdTwo

    this.setState(nextState)

  }

  updateCustomPrice(event) {
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.value
    const name = target.name

    nextState.pricing.custom[name] = parseInt(value, 10)
    this.setState(nextState)
    this.calculatePricing()

  }

  updateSessionStatus(status) {
    let nextState = {
      ...this.state
    }
    nextState.sessionExists = status
    this.setState(nextState)
  }

  updateMC(mc) {
    let nextState = {
      ...this.state
    }
    nextState.marketingConsultant = mc
    this.setState(nextState)
  }

  //updates business category from input field
  updateBusinessCategory(event) {
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;

    nextState.planDetails.businessCategory = value

    this.setState(nextState)
  }

  updateEmployeeNumber(event) {
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;

    nextState.planDetails.employeeNumber = value

    this.setState(nextState)
  }

  updateYearsInBusiness(event) {

    console.log('update years in business')
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;

    nextState.planDetails.yearsInBusiness = value

    this.setState(nextState)
  }

  //updates chosen marketing area from input field
  updateMarketingArea(event) {
    //when user updates the marketing area also set a chosen pricing object
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value;

    if (value === 'custom') {
      //MC selected Custom pricing
      nextState.planDetails.customPricingAvailable = true
    }

    nextState.planDetails.marketingArea = value

    this.setState(nextState)
    this.calculatePricing()
  }

  //updates individual marketing goals from input field
  updateMarketingGoals(event) {
    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value
    const name = target.name

    nextState.planDetails.marketingGoals[name] = value

    this.setState(nextState)
    this.calculateLeads()
  }

  //updates individual user values from input field
  updateUser(event) {

    let nextState = {
      ...this.state
    }

    const target = event.target
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value
    const name = target.name

    nextState.user[name] = value

    //if full name changes parse values into first and last names
    if (name === 'fullName') {
      let { firstName, lastName } = parseName(value)
      nextState.user.firstName = firstName
      nextState.user.lastName = lastName
    }

    this.setState(nextState)
  }

  handlePlanUpdate(event) {
    // console.log('event.currentTarget: ', event.currentTarget)
    // console.log('updating plan event.target.name: ',event.currentTarget)
    // console.log('updating plan event.target.value: ', event.currentTarget)

    let nextState = {
      ...this.state
    }
    nextState.planDetails[event.currentTarget.name] = (event.currentTarget.value === 'true')

    if (event.currentTarget.value === 'true') {
      switch (event.currentTarget.name) {
        case 'trl':
          nextState.planDetails.base = true
          break;
        case 'nla':
          nextState.planDetails.base = true
          break;
        case 'smm':
          nextState.planDetails.base = true
          break;
        case 'sta':
          nextState.planDetails.base = true
          break;
        case 'tpa':
          nextState.planDetails.base = true
          break;
        case 'ret':
          nextState.planDetails.base = true
          break;
        case 'me':
          nextState.planDetails.base = true
          break;
        case 'creative':
          nextState.planDetails.base = true
          break;
        case 'uxi':
          nextState.planDetails.base = true
          break;
        case 'em3':
          nextState.planDetails.base = true
          break;
        case 'sms':
          nextState.planDetails.base = true
          break;
        case 'lla':
          nextState.planDetails.base = true
          break;
        case 'analytics':
          nextState.planDetails.base = true
          break;
        default:
          break;

      }
    }
    this.calculatePricing()
    this.setState(nextState)
  }

  //updates entire user object
  updateUserObject(user) {
    // console.log('update user object')
    let nextState = {
      ...this.state
    }
    nextState.user = user
    this.setState(nextState)

    this.setLeadIdCookie(user.id)
  }

  updateUserAndMCObject(user, mc) {

    let nextState = {
      ...this.state
    }
    nextState.user = user
    nextState.marketingConsultant = mc
    this.setState(nextState)

  }

  updateUserMCAndPlanObject(user, mc, plan) {
    let nextState = {
      ...this.state
    }
    nextState.user = user
    nextState.marketingConsultant = mc
    nextState.planDetails = plan
    this.setState(nextState)
  }

  //updates entire plan object
  updatePlanObject(plan) {
    // console.log('update plan object')
    let nextState = {
      ...this.state
    }
    nextState.planDetails = plan
    this.setState(nextState)

    this.setPlandIdCookie(plan.id)
  }

  //updates user and plan object
  updateUserAndPlanObject(user, plan) {
    // console.log('update user and plan objects')
    let nextState = {
      ...this.state
    }
    nextState.user = user
    nextState.planDetails = plan
    this.setState(nextState)

    this.setPlandIdCookie(plan.id)
    this.setLeadIdCookie(user.id)
  }

  updatePricingObject(pricingObject) {
    let nextState = {
      ...this.state
    }
    nextState.pricing = pricingObject
    this.setState(nextState)
  }

  //take the marketing goals and put them in a string based on priority
  calculateLeads() {

    let leadString = ''
    let counter = 0

    _.forEach(this.state.planDetails.marketingGoals, function (value, key) {

      // console.log('key: ', key)

      if (value === true && counter < 2) {
        if (counter === 1) {
          leadString = leadString + ', ' + key
        } else {
          leadString = key
        }
        counter++
      }

    })

    let nextState = {
      ...this.state
    }
    if (counter === 0) {
      leadString = 'leads'
    }
    nextState.planDetails.leads = leadString
    this.setState(nextState)

  }

  calculatePricing() {

    // console.log('calculating pricing')

    let nextState = {
      ...this.state
    }
    let basePrice = 395;
    let marketingArea = nextState.planDetails.marketingArea
    let goodPrice = 0
    let betterPrice = 0
    let bestPrice = 0

    if (!_.isEmpty(nextState.pricing) && marketingArea !== 'default') {

      if (nextState.pricing) {
        basePrice = nextState.pricing[marketingArea].base
      }
      if (nextState.planDetails.base === true) {
        goodPrice = basePrice
        betterPrice = basePrice
        bestPrice = basePrice
      }
      if (nextState.planDetails.trl === true) {
        goodPrice += nextState.pricing[marketingArea].trlGood
        betterPrice += nextState.pricing[marketingArea].trlBetter
        bestPrice += nextState.pricing[marketingArea].trlBest
      }
      if (nextState.planDetails.nla === true) {
        goodPrice += nextState.pricing[marketingArea].nlaGood
        betterPrice += nextState.pricing[marketingArea].nlaBetter
        bestPrice += nextState.pricing[marketingArea].nlaBest
      }
      if (nextState.planDetails.smm === true) {
        goodPrice += nextState.pricing[marketingArea].smmGood
        betterPrice += nextState.pricing[marketingArea].smmBetter
        bestPrice += nextState.pricing[marketingArea].smmBest
      }
      if (nextState.planDetails.sta === true) {
        goodPrice += nextState.pricing[marketingArea].staGood
        betterPrice += nextState.pricing[marketingArea].staBetter
        bestPrice += nextState.pricing[marketingArea].staBest
      }
      if (nextState.planDetails.tpa === true) {
        goodPrice += nextState.pricing[marketingArea].tpaGood
        betterPrice += nextState.pricing[marketingArea].tpaBetter
        bestPrice += nextState.pricing[marketingArea].tpaBest
      }
      if (nextState.planDetails.ret === true) {
        goodPrice += nextState.pricing[marketingArea].retGood
        betterPrice += nextState.pricing[marketingArea].retBetter
        bestPrice += nextState.pricing[marketingArea].retBest
      }

      nextState.planDetails.planTotals.good = goodPrice
      nextState.planDetails.planTotals.better = betterPrice
      nextState.planDetails.planTotals.best = bestPrice

      // console.log('next state: ', nextState)

      this.setState(nextState)
    }
  }

  //generates an object from the query string
  getQueryString(queryString) {
    let query = (queryString || window.location.search).substring(1)
    if (!query) {
      return false;
    }
    return _.chain(query.split('&')).map(function (params) {
      var p = params.split('=')
      return [
        p[0],
        decodeURIComponent(p[1])
      ]
    }).fromPairs().value();
  }

  setPlandIdCookie(planId) {
    // console.log(planId)
    document.cookie = `planId=${planId}`
  }

  setLeadIdCookie(leadId) {
    document.cookie = `leadId=${leadId}`
  }

  //resend text to currently stored number
  //if successful redirect to login page
  //else redirect to login form or maybe edit page
  resendText() {
    let that = this
    resendCodeByMobilePhone(that.state.api, that.state.user.mobilePhone, function (err, response) {

      // console.log('response from resend text: parent function: ', response)

      if (err) {
        //forward to retry page
        console.log('error from resend text: ', err)
        history.push('/')
      } else {
        //forward to login page
        // console.log('attemp redirect to login page')
        history.push('/login')
      }

    })
  }

  setStateWithPlanId(planId, cb) {

    console.log('inside state with plan')

    let that = this
    let newPlanState = {}
    //get owner from api
    getPlan(that.state.api, planId, function (err, planObject) {

      if (err || !planObject) {
        console.log('error looking up plan: ', err)
        cb(err)
      } else {

        //1st determine if planId matches the user, if no user is selected: update user.
        //if user does not match plan redirect to error handling page
        // console.log("that.state.user.id: ", that.state.user.id)
        // console.log("planObject.owner.id: ", planObject.owner.id)
        if (that.state.user.id === 'defaultId' || that.state.user.id === planObject.owner.id) {
          //can update user and proceed

          //check version of plan data
          if (planObject.version < 3) {
            //parse old plan version
            newPlanState = parseV1PlanObject(that.state.planDetails, planObject)
            postUpdate(that.state.api, planObject.id, JSON.stringify(newPlanState), JSON.stringify(['updating to version 3']))
          } else {
            newPlanState = planObject
          }

          console.log('planObject.owner: ', planObject.owner)

          let marketingConsultant = formatMarketingConsultant(planObject.owner)

          if (marketingConsultant) {
            that.updateUserMCAndPlanObject(planObject.owner, marketingConsultant, newPlanState)
          } else {
            that.updateUserAndPlanObject(planObject.owner, newPlanState)
          }

          cb(null) //success

        } else {
          //id has been set and does not match plan owner
          console.log('handle mismatch user id')
          cb('handle mismatch user id')
        }

      }
    })
  }

  //updates the state of the app with the user
  setStateWithLeadId(ownerId) {

    let that = this
    //get owner from api
    getUser(that.state.api, ownerId, function (err, userObject) {
      if (err) {
        console.log(err)
      } else {

        // console.log('user object: ', userObject)

        if (userObject !== null) {

          //update owner objects

          let { firstName, lastName } = parseName(userObject.fullName)

          let newUserObject = {
            id: userObject.id,
            fullName: userObject.fullName,
            company: userObject.company,
            website: userObject.website,
            email: userObject.email,
            mobilePhone: userObject.mobilePhone,
            firstName,
            lastName
          }

          let marketingConsultant = formatMarketingConsultant(userObject)
          if (marketingConsultant) {
            that.updateUserAndMCObject(newUserObject, marketingConsultant)
          } else {
            that.updateUserObject(newUserObject)
          }

        } else {

          //user not found. do nothing?

        }

      }

    })

  }

  //when app mounts do three things: fetch pricing from pricing spreadsheet, check for query to load existing user
  //check for cookie to load existing user
  componentWillMount() {

    //check for parameter
    let params = this.getQueryString()

    //check for cookie
    // let cookies = document.cookie.replace(/\s/g, '')
    // let cookieObject = _.fromPairs(cookies.split(';').map(s => s.split('=')))

    console.log('component mounting')

    if (params.plan) {

      console.log('setting state with plan')

      this.setStateWithPlanId(params.plan, function (err) {
        if (err) {
          console.log(err)
        }
      })
    } else if (params.id) {
      //set state based on parameter
      this.setStateWithLeadId(params.id)

    }
    // else if(cookieObject.planId){
    //      console.log('3: setting plan with cookie')
    //     set state based on cookie
    //     this.setStateWithPlanId(cookieObject.planId, function (err) {
    //         if(err){
    //             console.log(err)
    //         }
    //     })
    // }
    // else if(cookieObject.owner){
    //      console.log('4')
    //     set state based on cookie
    //     this.setStateWithLeadId(cookieObject.owner)
    // }

  }

  componentDidUpdate() {

    if (this.state.user.id !== 'defaultId' && this.state.sessionExists === false) {
      // console.log('connecting socket')
      io.sails.url = this.state.api;
      io.sails.query = `id=${this.state.user.id}`
      io.sails.connect()
      this.updateSessionStatus(true)
    }



  }

  render() {

    return (<Router >
      <div className="plans-pricing">
        <Switch>

          <Route exact path="/" render={() => < LoginPrompt
            history={
              history
            }
            user={
              this.state.user
            }
            marketingConsultant={this.state.marketingConsultant}
            api={
              this.state.api
            }
            updateUser={
              this.updateUser
            }
            resendText={
              this.resendText
            }
          />} />

          {/* <Route path="/check" render={() =>< LoginPrompt
            checkModalIsActive = {
              true
            }
            history = {
              history
            }
            user = {
              this.state.user
            }
            marketingConsultant = {this.state.marketingConsultant}
            api = {
              this.state.api
            }
            updateUser = {
              this.updateUser
            }
            resendText = {
              this.resendText
            }
            />}/> */}

          <Route path="/login" render={() => < Login
            history={
              history
            }
            user={
              this.state.user
            }
            updateUser={
              this.updateUser
            }
            resendText={
              this.resendText
            }
            updatePlanId={
              this.updatePlanId
            }
            setStateWithPlanId={
              this.setStateWithPlanId
            }
            planDetails={
              this.state.planDetails
            }
            api={
              this.state.api
            }
            marketingConsultant={
              this.state.marketingConsultant
            }
          />} />

          {/* <Route path="/new-plan" render={() =>< InitialRequestComponent
            updateUser = {
              this.updateUser
            }
            user = {
              this.state.user
            }
            api = {
              this.state.api
            }

            />}/> */}

          <Route path="/plans" render={() => <LetterComponent
            marketingConsultant={this.state.marketingConsultant}
            user={this.state.user}
            plan={this.state.planDetails}
          />} />

          <Route path="/" render={() => < MainComponent
            history={
              history
            }

            plan={
              this.state.planDetails
            }
            user={
              this.state.user
            }
            step={
              this.state.planDetails.currentStep
            }
            marketingConsultant={
              this.state.marketingConsultant
            }
            furthestStep={
              this.state.planDetails.furthestStep
            }
            pricing={
              this.state.pricing
            }
            api={
              this.state.api
            }

            updateMC={
              this.updateMC
            }
            updateCustomPrice={
              this.updateCustomPrice
            }
            handlePlanUpdate={
              this.handlePlanUpdate
            }
            updateMarketingGoals={
              this.updateMarketingGoals
            }
            updateBusinessCategory={
              this.updateBusinessCategory
            }
            updateEmployeeNumber={
              this.updateEmployeeNumber
            }
            updateYearsInBusiness={
              this.updateYearsInBusiness
            }
            updateMarketingArea={
              this.updateMarketingArea
            }
            calculateLeads={
              this.calculateLeads
            }
            setStep={
              this.setStep
            }
            prevStep={
              this.prevStep
            } />} />

        </Switch>
      </div>
    </Router>)
  }
}

export default PlansPricing
