import React from 'react'
import './TextAreaComponent.css'


function TextAreaComponent(props) {

        let content = props.textContent

        let parts = content.split('#')

        let dynamicString = ''

        if(parts.length > 1){
            dynamicString = parts[0] + props.variable + parts[1]
        }else{
            dynamicString = parts[0]
        }


        return (
            <div className="TextAreaComponent">
                <p className={props.textType}>
                    {dynamicString}
                </p>
            </div>
        )
}


export default TextAreaComponent
