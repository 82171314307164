import React from 'react'
import './ModalComponent.css'



function ModalComponent(props) {
    return(
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <i  onClick={props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>
                <h2 className="modal-title">{props.moreInfo}</h2>
                <p className="modal-info">Before we add this to your plan, please select a program below.</p>
                <p className="modal-desc">(don't worry, you can always change this later)</p>

                <div className="plan-option">
                    <div className="plan-option-title">
                        <h3>Good</h3>
                        <p>300 CREDITS /MONTH</p>
                        <button className="btn btn-primary">Select</button>
                    </div>
                    <div className="plan-option-desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default ModalComponent