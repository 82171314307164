import React, { Component } from 'react'
import './LetterHeader.css'

import { Link, withRouter } from 'react-router-dom'

import HeaderModalComponent from './modals/HeaderModalComponent'
import PhoneModalComponent from './modals/PhoneModalComponent'
import LetterShareModal from './modals/LetterShareModal'
let logo = 'https://s3-us-west-2.amazonaws.com/planbuilder/logo-marketing360.png'
let calogo = 'https://marketing360-website-ca.s3.amazonaws.com/LockupLightLogo.svg'

const pageInfo = {
    ca: "ca head text",
    com: "usa head text"
  };

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            headerModalIsActive: false,
            phoneModalIsActive: false,
            shareModalIsActive: false,
            isCopied: false,
            showUsaLetter: false,
            showCaLetter: false,
            pageKey: null
        }

        this.toggleShareModal = this.toggleShareModal.bind(this)
        this.toggleHeaderModal = this.toggleHeaderModal.bind(this)
        this.togglePhoneModal = this.togglePhoneModal.bind(this)
        this.onCopySuccess = this.onCopySuccess.bind(this)
    }
    componentDidMount() {
        let currentPage = window.location.hostname;
        this.detectPageURL(currentPage);
    }

    toggleShareModal(){
        this.setState({
            shareModalIsActive: !this.state.shareModalIsActive,
            headerModalIsActive: false,
            phoneModalIsActive: false
        })
    }

    toggleHeaderModal(){
        this.setState({
            headerModalIsActive: !this.state.headerModalIsActive,
            phoneModalIsActive: false,
            shareModalIsActive: false
        })
    }

    togglePhoneModal(){
      this.setState({
          phoneModalIsActive: !this.state.phoneModalIsActive,
          headerModalIsActive: false,
          shareModalIsActive: false
      })
    }

    convertStep(step) {
        return (step / this.props.plan.totalSteps) * 100;
    }

    onCopySuccess() {
        this.setState({
            isCopied: true
        })
    }

    printWindow(){
        window.print()
      }
      detectPageURL = currentPage => {
        let thisKey;
        if (currentPage === "planbuilder.marketing360.ca") {
          thisKey = "ca head";
        } else {
          thisKey = "com head";
        }
        this.setState(() => ({
          pageURL: currentPage && currentPage,
          showUsaLetter:
            currentPage === "planbuilder.marketing360.com" ? true : false,
          showCaLetter:
            currentPage === "planbuilder.marketing360.ca" ? true : false,
          pageKey: thisKey
        }));
        console.log(currentPage);
      };
    render() {

        let callLink = "tel:833-277-1327"
        if(this.props.marketingConsultant.phonenumber){
            callLink = `tel:${this.props.marketingConsultant.phonenumber}`
        }

        let headerModal = null
        let shareModal = null

        let shareButtons =  <div className="shareButtons">
                                <a onClick={this.toggleShareModal}>Share <i className="fa fa-share-square-o" aria-hidden="true"></i></a>
                                {/* <a onClick={this.printWindow} >Print <i className="fa fa-print" aria-hidden="true"></i></a> */}
                            </div>

        if(false){

            let mcImageUrl = this.props.marketingConsultant.imageurl;
            mcImageUrl = mcImageUrl.replace("150", "50");

        }

        if(this.state.headerModalIsActive){
            headerModal = <HeaderModalComponent key="header-modal" toggleModal={this.toggleHeaderModal} furthestStep={this.props.plan.furthestStep} />
        }

        let phoneModal = null

        if(this.state.phoneModalIsActive){
            phoneModal = <PhoneModalComponent key="phone-modal"
                          toggleModal={this.togglePhoneModal}
                          marketingConsultant={this.props.marketingConsultant}
                          />
        }

        if(this.state.shareModalIsActive){
            shareModal = <LetterShareModal toggleModal={this.toggleShareModal} planId={this.props.plan.id} onCopySuccess={this.onCopySuccess} isCopied={this.state.isCopied} />
        }

        return (
            <div>
        {this.state.showCaLetter && (
          <div
            className={`LetterHeader header ${this.state.pageKey &&
              this.state.pageKey}> ${
              this.state.pageKey ? pageInfo[this.state.pageKey] : "some ca head text"
            }`}
          >

                {headerModal}
                {shareModal}
                {phoneModal}

                <nav className="navbar navbar-dark">
                        <div className="navbar-header">
                            <div className="col-xs-2 col-sm-4">
                                <a className="callLink" onClick={this.togglePhoneModal}><i className="fa fa-phone"></i></a>
                            </div>
                            <div className="col-xs-7 col-sm-4 logo-custom-size">
                                <Link to={{ pathname: '/plans', search: this.props.location.search}}> <img src={calogo} className="App-logo" alt="logo" /></Link>
                            </div>
                            <div className="col-xs-3 col-sm-4 buttons-custom-size">
                                {shareButtons}
                            </div>

                        </div>
                </nav>
            </div>
        )}
        {this.state.showUsaLetter && (
          <div
            className={`LetterHeader header ${this.state.pageKey &&
              this.state.pageKey}> ${
              this.state.pageKey
                ? pageInfo[this.state.pageKey]
                : "some com head text"
            }`}
          >
              {headerModal}
                {shareModal}
                {phoneModal}

                <nav className="navbar navbar-dark">
                        <div className="navbar-header">
                            <div className="col-xs-2 col-sm-4">
                                <a className="callLink" onClick={this.togglePhoneModal}><i className="fa fa-phone"></i></a>
                            </div>
                            <div className="col-xs-7 col-sm-4 logo-custom-size">
                                <Link to={{ pathname: '/plans', search: this.props.location.search}}> <img src={logo} className="App-logo" alt="logo" /></Link>
                            </div>
                            <div className="col-xs-3 col-sm-4 buttons-custom-size">
                                {shareButtons}
                            </div>

                        </div>
                </nav>
          </div>
        )}
        </div>
        )
    }
}

export default withRouter(Header)
