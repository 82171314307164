import React from 'react'
import './MCProfileComponent.css'
import MCDescription from './subComponents/MCDescription.js'
import { formatPhoneNumber } from './helpers/Funcs'

function MCProfileComponent(props){

    let mcTitle = `Hi, I'm ${props.marketingConsultant.firstname}`
    let mcPhone = `${props.marketingConsultant.phonenumber}`
    let mcPhoneLink = `tel:${mcPhone}`
    let calendyContent = null
    let mcImageUrl = `${props.marketingConsultant.imageurl}`
    let formattedPhoneNumber = ''

    if(props.marketingConsultant.phonenumber){
      formattedPhoneNumber = `${formatPhoneNumber(props.marketingConsultant.phonenumber)}`
    } else{
      formattedPhoneNumber = '833-277-1327'
    }

    if(props.marketingConsultant.calendyurl){
      calendyContent = <div>
                        <p>Or, schedule a time here:</p>
                        <p><a className="btn btn-primary" href={props.marketingConsultant.calendyurl}>schedule meeting</a></p>
                      </div>
    }

    return(
      <div className="mc-profile">
        <img className="mc-image" src={mcImageUrl} alt="mc"/>
        <h3>{mcTitle}</h3>
        <MCDescription type={props.type} />
        <p>Feel free to call me at:</p>
        <p><a className="phone-link" href={mcPhoneLink}>{formattedPhoneNumber}</a></p>
        {calendyContent}
      </div>
    )

}

export default MCProfileComponent
