import React,{Component} from 'react'
import './ResendModalComponent.css'

import {resendCodeByMobilePhone} from '../helpers/apiHelper'

class ResendModalComponent extends Component {

    constructor(props){
        super(props)

        this.state = {
            errorMessage: null
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){

        let that = this

        resendCodeByMobilePhone(this.props.api, this.props.user.mobilePhone, function(err, response){
            if(err){
                //set error message and push to request page
                console.log('[resendModalComponent] handle error')
                // that.props.history.push('/')
                that.setState({
                    errorMessage: 'We could not find that number in our records. Please try again or create a new plan request.'
                })
            }else{
                // push to login page
                console.log('[resendModalComponent] success on resend, response: ', response)
                that.props.toggleModal()
                that.props.history.push('/login')
            }
        })

    }

    render(){

        let {errorMessage} = this.state

        return(
            <div id="ResendModalComponent" className="modal-overlay">
                <div className="modal-header">
                    <i onClick={this.props.toggleModal} className="fa fa-times" aria-hidden="true"></i>
                </div>
                <div className="modal-content">
                    <p className="text-danger">{errorMessage}</p>
                    <h2 className="modal-title bottom20">Please enter the mobile number you used so we can find & resend your access code.</h2>

                    <div className="modal-box container-form">
                        <div className="form-group bottom0">
                            <input onChange={this.props.handleChange} name='mobilePhone' className="form-control bottom20" type="tel" required/>
                            <button className="btn btn-primary" onClick={this.handleClick} >Find & Resend Access Code</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export default ResendModalComponent








