import React, { Component } from 'react'
import './LetterComponent.css'
import { withRouter } from 'react-router-dom'


import ModalComponent from './modals/ModalComponent'
import ModalComponentVideo from './modals/ModalComponentVideo'
import ModalComponentPlan from './modals/ModalComponentPlan'
import ShareModalComponent from './modals/ShareModalComponent'
import MCProfileComponent from './MCProfileComponent'
import OperationHours from './subComponents/OperationHours'
import PlanTotalRow from './subComponents/PlanTotalRow'
import LetterContent from './subComponents/LetterContent'
import LetterFooter from './subComponents/LetterFooter'
import MCLetterComponent from './MCLetterComponent'
import LetterJumbo from './LetterJumbo'

import {cdnRoute} from './helpers/apiHelper'
import LetterHeader from './LetterHeader';

let arrowPointer = `${cdnRoute}/arrow-pointer.png`
let iconMarketingFuel = `${cdnRoute}/icon-marketing-fuel.png`
const pageInfo = {
    ca: "CaNoJumbo",
    com: "UsaJumbo"
  };

class LetterComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            infoModalIsActive: false,
            overviewModalIsActive: false,
            googleIsActive: false,
            facebookIsActive: false,
            planModalIsActive: false,
            shareModalIsActive: false,
            showBaseMessage: false,
            modalDetails: 'crmDetails',
            isCopied: false,
            showUsaLetter: false,
            showCaLetter: false,
            pageKey: null
        }

        this.toggleInfoModal = this.toggleInfoModal.bind(this)
        this.toggleShareModal = this.toggleShareModal.bind(this)
        this.toggleOverviewModal = this.toggleOverviewModal.bind(this)
        this.toggleGoogleModal = this.toggleGoogleModal.bind(this)
        this.toggleFacebookModal = this.toggleFacebookModal.bind(this)
        this.togglePlanModal = this.togglePlanModal.bind(this)
        this.openPlanModal = this.openPlanModal.bind(this)
        this.onCopySuccess = this.onCopySuccess.bind(this)
        this.handleBasePlatformChange = this.handleBasePlatformChange.bind(this)
        this.closeBaseMessage = this.closeBaseMessage.bind(this)
    }
    

    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = "https://badge.topratedlocal.com/load-badge/block?badge=show&ratings=all&comments=show";
        this.instance.appendChild(s);
        let currentPage = window.location.hostname;
        this.detectPageURL(currentPage);
    }

    handleBasePlatformChange(event){
        //if products that require the base package are active, don't let
        //lead remove base package
        if (this.props.plan.trl || this.props.plan.nla || this.props.plan.smm || this.props.plan.sta || this.props.plan.tpa || this.props.plan.ret) {
            this.setState({
                showBaseMessage: true
            })
        } else {
            this.props.handlePlanUpdate(event)
        }
    }
    closeBaseMessage(){
        this.setState({
            showBaseMessage: false
        })
    }

    toggleInfoModal(){
        this.setState({
            infoModalIsActive: !this.state.infoModalIsActive,
            overviewModalIsActive: false,
            googleIsActive: false,
            facebookIsActive: false,
            shareModalIsActive: false,

        })
    }

    toggleShareModal(){
        this.setState({
            shareModalIsActive: !this.state.shareModalIsActive,
            infoModalIsActive: false,
            overviewModalIsActive: false,
            googleIsActive: false,
            facebookIsActive: false,
            isCopied: false
        })
    }

    toggleOverviewModal(){
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: !this.state.overviewModalIsActive,
            googleIsActive: false,
            facebookIsActive: false,
            shareModalIsActive: false,

        })
    }

    toggleGoogleModal(){
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: false,
            googleIsActive: !this.state.googleIsActive,
            facebookIsActive: false,
            shareModalIsActive: false,

        })
    }

    toggleFacebookModal(){
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: false,
            googleIsActive: false,
            facebookIsActive: !this.state.facebookIsActive,
            shareModalIsActive: false,

        })
    }

    togglePlanModal(){
        this.setState({
            planModalIsActive: !this.state.planModalIsActive
        })
    }

    openPlanModal(key){

        let nextState = {...this.state}
        nextState.planModalIsActive = !this.state.planModalIsActive

        if (key) {
            nextState.modalDetails = key
        }

        this.setState(nextState)
    }

    onCopySuccess() {
        this.setState({
            isCopied: true
        })
    }

    printWindow(){
      window.print()
    }
    detectPageURL = currentPage => {
        let thisKey;
        if (currentPage === "planbuilder.marketing360.ca") {
          thisKey = "CaNoJumbo";
        } else {
          thisKey = "UsaJumbo";
        }
        this.setState(() => ({
          pageURL: currentPage && currentPage,
          showUsaLetter:
            currentPage === "planbuilder.marketing360.com" ? true : false,
          showCaLetter:
            currentPage === "planbuilder.marketing360.ca" ? true : false,
          pageKey: thisKey
        }));
        console.log(currentPage);
      };
    render() {

        let infoModal = null
        let overviewModal = null
        let googleModal = null
        let facebookModal = null
        let baseMessage = null
        let shareModal = null
        let mediaBuyMessage = null

        if(this.state.infoModalIsActive){
            infoModal = <ModalComponent toggleModal={this.toggleInfoModal} moreInfo={this.props.details.moreInfo}/>
        }
        if(this.state.overviewModalIsActive){
            overviewModal = <ModalComponentVideo toggleModal={this.toggleOverviewModal} videoUrl="https://www.youtube.com/embed/dkiZuMZ0-wo?rel=0&autoplay=1" />
        }
        if(this.state.googleIsActive){
            googleModal = <ModalComponentVideo toggleModal={this.toggleGoogleModal} videoUrl="https://www.youtube.com/embed/vIkewUs4u3k?rel=0&autoplay=1" />
        }
        if(this.state.facebookIsActive){
            facebookModal = <ModalComponentVideo toggleModal={this.toggleFacebookModal} videoUrl="https://www.youtube.com/embed/Z4xjVp77zW8?rel=0&autoplay=1" />
        }

        if(this.state.shareModalIsActive){
            shareModal = <ShareModalComponent toggleModal={this.toggleShareModal} planId={this.props.plan.id} onCopySuccess={this.onCopySuccess} isCopied={this.state.isCopied} />
        }

        let jumboContent =  <LetterJumbo user={this.props.user}/>

        let dynamicClass = `LetterComponent component body short`

        if(this.props.marketingConsultant.mcAssigned){
            jumboContent =  <MCLetterComponent
                                marketingConsultant={this.props.marketingConsultant}
                            />
            dynamicClass =  `LetterComponent component body`
        }

        return (
            <div
            className={`ca-display-none ${this.state.pageKey &&
              this.state.pageKey} ${
              this.state.pageKey ? pageInfo[this.state.pageKey] : "SomeClass"
            }`}
          >
            <div id="letter-component" className={dynamicClass}>

                {infoModal}
                {overviewModal}
                {googleModal}
                {facebookModal}
                {shareModal}

                <LetterHeader
                    marketingConsultant={this.props.marketingConsultant}
                    user ={ this.props.user }
                    plan ={this.props.plan}
                />
                <section className="letter-section-jumbo">
                    <div className="container">
                        { jumboContent }
                    </div>
               </section>

                <LetterContent user={this.props.user} marketingConsultant={this.props.marketingConsultant} />

                <div>

                    <div ref={el => (this.instance = el)} />

                    <LetterFooter
                        toggleOverviewModal={this.toggleOverviewModal}
                        toggleGoogleModal={this.toggleGoogleModal}
                        toggleFacebookModal={this.toggleFacebookModal}
                        googleIsActive={this.state.googleIsActive}
                        facebookIsActive={this.state.facebookIsActive}
                    />

                </div>

            </div>
            </div>
        )
    }
}


export default withRouter(LetterComponent)
