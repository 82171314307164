const parseName = fullName => {
  let ret = {
    firstName: undefined,
    lastName: undefined
  }

  if (!fullName || (typeof fullName !== 'string'))
    return ret

  let nameArray = fullName.split(" ")

  if (!nameArray.length)
    return ret

  ret.firstName = nameArray.shift()
  if (!nameArray.length)
    return ret

  ret.lastName = nameArray.join(' ')

  // if(nameArray[0] !== null && nameArray[0] !== undefined ){
  //     ret.firstName = nameArray[0]
  // }
  // if(nameArray[1] !== null && nameArray[1] !== undefined ){
  //     ret.lastName = nameArray[1]
  // }
  return ret
}

export { parseName }

const numberWithCommas = (x) => {
  if (x !== null && x !== undefined) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return x
  }
}

export { numberWithCommas }

const formatPhoneNumber = (phone) => {

  phone = phone.replace(/\D/g, '');

  let newString = ''

  if (phone.length === 10) {
    newString += phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6)
    return newString
  } else {
    return phone
  }
}

export { formatPhoneNumber }


const formatMarketingConsultant = (lead) => {

  console.log('lead: ', lead)

  if (lead.marketingConsultant) {
    let mcObject = JSON.parse(lead.marketingConsultant)
    let mc = {}
    console.log('mcObject: ', mcObject)

    if (!mcObject.response) {
      return null
    }
    mc = mcObject.response

    console.log('mc: ', mc)

    if (mc.id != null
      && mc.fullname != null
      && mc.imageurl != null) {
      mc.mcAssigned = true
      return mc
    } else {
      return null
    }
  } else {
    return null
  }
}

export { formatMarketingConsultant }
