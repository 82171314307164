import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import './InitialRequestComponent.css'

import HeaderInit from './HeaderInit'
import PlanRequest from './PlanRequest'



class InitialRequestComponent extends Component {

    // constructor(props) {
    //     super(props)
    // }


    render() {


        return (
            <div className="InitialRequestComponent page-with-header container-form">
                <HeaderInit prevStep={this.props.prevStep} user={this.props.user} step={this.props.step}/>

                <Route path="/new-plan" exact render={()=><PlanRequest
                    updateUser={this.props.updateUser}
                    user={this.props.user}
                    api={this.props.api}
                    /> }/>

            </div>
        )
    }
}

export default InitialRequestComponent
