import React from 'react'
import {cdnRoute} from '../helpers/apiHelper'
let vidKnowUs = `${cdnRoute}/vid-know-us.jpg`
let vidGoogle = `${cdnRoute}/vid-google.jpg`
let vidFacebook = `${cdnRoute}/vid-facebook.jpg`
let awardInc5000 = `${cdnRoute}/award-inc5000.png`
let awardBing = `${cdnRoute}/award-bing.png`
let awardGlassdoor = `${cdnRoute}/award-glassdoor.png`
let awardTop10 = `${cdnRoute}/award-top10.png`
let awardFamilyOwned = `${cdnRoute}/award-family-owned.png`

const LetterFooter = (props) => {

    let trlBlock = <a href="http://www.topratedlocal.com/marketing-360-reviews" id="trl-badge-poweredby-block">Powered by Top Rated Local&reg;</a>
    let trlClasses = "top60 bottom60"

    if(props.googleIsActive || props.facebookIsActive){
        trlBlock = null
        trlClasses = "top60 bottom60 hide"
    }

    return(
        <div className="container-letter text-center">

            <div className="top60 bottom60">
                <h3 className="h4-mobile">Please take 10 minutes and get to know us</h3>
                <p>
                    <a className="hover-fade" onClick={props.toggleOverviewModal} ><img className="img-responsive" src={vidKnowUs} alt="" /></a>
                </p>
            </div>

            <div className="top60 bottom60">
                <h3 className="h4-mobile bottom20">See What Google Has to Say About Us</h3>
                <p className="bottom20">
                    <a className="hover-fade" onClick={props.toggleGoogleModal} ><img className="img-responsive" src={vidGoogle} alt="" /></a>
                </p>
            </div>

            <div className="top60 bottom60">
                <h3 className="h4-mobile bottom20">See what Facebook has to say about us</h3>
                <p className="bottom20">
                    <a className="hover-fade" onClick={props.toggleFacebookModal} ><img className="img-responsive" src={vidFacebook} alt="" /></a>
                </p>
            </div>

            <div className={trlClasses}>
                <h3 className="h4-mobile">Read Verified Marketing 360&reg; Reviews</h3>

                {/* <div ref={el => (this.instance = el)} /> */}
                {trlBlock}
            </div>

            <div className="letter-awards top40 bottom40">
                <h3 className="h4-mobile text-center bottom40">Our Awards &amp; Accomplishments</h3>

                {/* <div className="row">
                    <div className="col-sm-3">
                        <p>
                            <img className="img-responsive" src="https://d1ewc3yxmcezew.cloudfront.net/img/badge-google-smb.png" alt="Google SMB Partner" />
                        </p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">Don't take our word for it, take Google's word for it!</h4>
                        <p className="text-md">We've been selected as 1 of only 25 Google PSP Partners in the world!</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-3">
                        <p>
                            <img className="img-responsive" src={awardBing} alt="Bing Authorized Reseller" />
                        </p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">Official Bing Advertising Partner!</h4>
                        <p className="text-md">We've been selected as 1 of only 13 Bing Advertising Partners in the world!</p>
                    </div>
                </div>
                <hr /> */}
                <div className="row">
                    <div className="col-sm-3">
                        <p><img className="img-responsive" src={awardTop10} alt="" /></p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">Top 10 Marketing Company</h4>
                        <p className="text-md">We have been ranked a national "Top 10 Marketing Company" by Inc. 500</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-3">
                        <p><img className="img-responsive" src={awardGlassdoor} alt="" /></p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">#1 Best Place to Work</h4>
                        <p className="text-md">We have been rated the nation's #1 Best Place to Work by Glassdoor 3 years in a row!</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-3">
                        <p><img className="img-responsive" src={awardInc5000} alt="" /></p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">One of the Nation's Fastest Growing Companies</h4>
                        <p className="text-md">We have been recognized as one of the nation's fastest growing companies by Inc. 500, 5 years in a row.</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-3">
                        <p><img className="img-responsive" src={awardFamilyOwned} alt="" /></p>
                    </div>
                    <div className="col-sm-9">
                        <h4 className="letter-awards-title top0">Top 50 Family Owned Company</h4>
                        <p className="text-md">We have been honored as a Colorado Top 50 Owned Company 5 years in a row!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LetterFooter;