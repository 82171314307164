import request from 'superagent'


//takes a user id and an api endpoint
//return user object
export function getUser(apiEndpoint, userId, cb) {

    let planUrl = `${apiEndpoint}/getleadbyid?id=${userId}`

    request
        .get(planUrl)
        .end(function (err, res) {
            if (err) {
                console.log('error on getUser lead lookup', err)
            }
            else {

                // console.log('success on getUser lead lookup')

                if (res.statusCode === 200) {
                    // console.log('response: ', res.body)
                    cb(null, res.body)
                }
                else {
                    console.log('specific error on getUser lead lookup: ', res.statusCode)
                    //fire error
                    cb(res.statusCode, null)
                }

            }
        });


}

//takes a user id and an api endpoint
//return user object
export function getUserByPlanId(apiEndpoint, planId, cb) {

    let planUrl = `${apiEndpoint}/getleadbyplan?planid=${planId}`
    request
        .get(planUrl)
        .end(function (err, res) {
            if (err) {
                console.log('error on getUser lead lookup', err)
            }
            else {

                // console.log('success on getUser lead lookup')

                if (res.statusCode === 200) {
                    // console.log('response: ', res.body)
                    cb(null, res.body)
                }
                else {
                    console.log('specific error on getUser lead lookup: ', res.statusCode)
                    //fire error
                    cb(res.statusCode, null)
                }

            }
        });


}

export function getPlan(apiEndpoint, planId, cb) {

    let planUrl = `${apiEndpoint}/getplan?code=${planId}`

    console.log('*** inside get plan ***')

    request
        .get(planUrl)
        .end(function (err, res) {

            console.log('the request has ended')

            if (err) {
                console.log('error on getPlan lookup', err)
                cb(err, null)
            }
            else {

                console.log('success on getPlan lookup')

                if (res.statusCode === 200) {
                    console.log('response: ', res.body)
                    cb(null, res.body)
                }
                else {
                    console.log('specific error on getPlan lead lookup: ', res.statusCode)
                    //fire error
                    cb(res.statusCode, null)
                }

            }
        });


}


//takes an api endpoint and a phone number and resends notifications
export function resendCodeByMobilePhone(apiEndpoint, mobilePhone, cb) {

    let planUrl = `${apiEndpoint}/getlatestplan?mobilePhone=${mobilePhone}`

    request
        .get(planUrl)
        .end(function (err, res) {
            if (err) {
                console.log('error on resendCode lead lookup', err)
                cb(err, null)
            }
            else {

                // console.log('success on resendCode lead lookup')

                if (res.statusCode === 200) {
                    // console.log('response from resend code: ', res)
                    cb(null, res.statusCode)
                }
                else {
                    console.log('specific error on resendCode lead lookup: ', res.statusCode)
                    //fire error
                    cb(res.statusCode, null)
                }

            }
        });


}

//takes an api endpoint and a phone number and resends notifications
export function updateMobilePhoneByLeadId(apiEndpoint, leadId, mobilePhone, cb) {

    let planUrl = `${apiEndpoint}/updatephone`

    request
        .post(planUrl)
        .send({ id: leadId, mobilePhone: mobilePhone })
        .end(function (err, res) {
            if (err) {
                console.log('error on change phone number: ', err)
                cb(err, null)
            }
            else {

                // console.log('success on change phone number')

                if (res.statusCode === 200) {
                    // console.log('response from change number: ', res)
                    cb(null, res.statusCode)
                }
                else {
                    console.log('specific error on change number: ', res.statusCode)
                    //fire error
                    cb(res.statusCode, null)
                }

            }
        })

}

export function postUpdate(apiEndpoint, code, data, logEntry) {

    let updatePlanUrl = `${apiEndpoint}/update`

    let postBody = {
        code: code,
        data: data,
        logEntry: logEntry
    }

    if (code) {

        // console.log('firing post update')
        request.post(updatePlanUrl)
            .set('content-type', 'application/x-www-form-urlencoded')
            .send(postBody) // sends a JSON post body
            .end((err, res) => {
                // Calling the end function will send the request
                if (err) {
                    console.log('error on post update: ', err)
                }
                else {
                    // console.log('success on post update: ', res)
                }
            })
    }
}

export function getLog(added, title) {

    let logString = ''

    if (added) {
        logString = `Added ${title} to plan.`
    } else {
        logString = `Removed ${title} from plan.`
    }
    let logEntry = [logString]
    let logEntryString = JSON.stringify(logEntry)

    return logEntryString

}


export const cdnRoute = 'https://d36evp5uoqrlgl.cloudfront.net'
