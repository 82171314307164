import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import validator from 'validator'

import request from 'superagent'

import './PlanRequest.css'

const agent = request.agent()
class PlanRequest extends Component {


    constructor(props) {
        super(props)

        this.state = {
        	fireRedirect: false,
			errorMessage: null,
            formErrors: {
                name: '',
                companyName: '',
                website: '',
                email: '',
                mobilePhone: '',
            },
            formValid: false,
            nameValid: false,
            companyNameValid: false,
            websiteValid: false,
            emailValid: false,
            mobilePhoneValid: false,
		}

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

      }


      handleChange(event) {
        // console.log("[plan request] -> updating user")
        this.props.updateUser(event)
      }

      handleSubmit(e) {

          //maybe show loader
		  let newLeadUrl = `${this.props.api}/submit`

      	  // console.log('submit request form')
		  e.preventDefault()

          agent.post(newLeadUrl)
              .withCredentials()
              .set('content-type', 'application/x-www-form-urlencoded')
              .send({
                fullName: this.props.user.fullName,
				firstName: this.props.user.firstName,
			    lastName: this.props.user.lastName,
				company: this.props.user.company,
				website: this.props.user.website,
				email: this.props.user.email,
				mobilePhone: this.props.user.mobilePhone
              }) // sends a JSON post body
              .end((err, res) => {
                  // Calling the end function will send the request
				  if(err){
				  	console.log('error on create lead post', err)
                      this.setState({
                          errorMessage: 'Please try again'
                      })
				  }
				  else{
				  	console.log('success on create lead post', res)

					  if(res.statusCode === 200){

                          this.setState({ fireRedirect: true })
					  }
					  else{
					  	this.setState({
							errorMessage: 'Please try again'
						})
					  }
				  }
              })


      }


    render() {

        const { fireRedirect } = this.state
		let {errorMessage} = this.state

        let form = {

            fullName:{
                valid : true,
                error: null,
                ready: false
            },
            // firstName:{
            //     valid : true,
            //     error: null,
            //     ready: false
            // },
            // lastName:{
            //     valid : true,
            //     error: null,
            //     ready: false
            // },
            company:{
                valid : true,
                error: null,
                ready: false
            },
            website:{
                valid : true,
                error: null,
                ready: false
            },
            email: {
                valid : true,
                error: null,
                ready: false
            },
            mobilePhone: {
                valid : true,
                error: null,
                ready: false
            },
            valid: 'disabled'
        }


        //
        // if(this.props.user.firstName.length == 0){
        //     form.firstName.valid = false
        //     form.firstName.error = <small className='text-danger'>A first name is required.</small>
        // }else if(this.props.user.firstName !== 'defaultFirstName'){
        //     form.firstName.ready = true
        // }
        // if(this.props.user.lastName.length == 0){
        //     form.lastNameValid = false
        //     form.lastName.error = <small className='text-danger'>A last name is required.</small>
        // }else if(this.props.user.lastName !== 'defaultLastName'){
        //     form.lastName.ready = true
        // }
        if(this.props.user.fullName.length === 0){
            form.fullName.valid = false
            form.fullName.error = <small className='text-danger'>Your name is required.</small>
        }else if(this.props.user.fullName !== 'defaultFullName'){
            form.fullName.ready = true
        }
        if(this.props.user.company.length === 0){
            form.company.valid = false
            form.company.error = <small className='text-danger'>A company name is required.</small>
        }else if(this.props.user.company !== 'your company'){
            form.company.ready = true
        }
        if(this.props.user.website.length === 0){
            form.website.valid = false
            form.website.error = <small className='text-danger'>This field is required. (you can write 'na' or 'don't have one') </small>
        }else if(this.props.user.website !== 'defaultWebsite'){
            form.website.ready = true
        }
        if( !validator.isEmail(this.props.user.email) && this.props.user.email !== 'defaultEmail' ){
            form.email.valid = false
            form.email.error = <small className='text-danger'>Please enter a valid email address.</small>
        }else if(validator.isEmail(this.props.user.email)){
            form.email.ready = true
        }
        if( !validator.isMobilePhone(this.props.user.mobilePhone, 'any') && this.props.user.mobilePhone !== 'defaultMobilePhone'){
            form.mobilePhone.valid = false
            form.mobilePhone.error = <small className='text-danger'>Please enter a valid mobile phone number.</small>
        }else if(validator.isMobilePhone(this.props.user.mobilePhone, 'any')){
            form.mobilePhone.ready = true
        }

        if(form.fullName.ready && form.company.ready && form.email.ready && form.mobilePhone.ready && form.website.ready){
            form.valid = null
        }

        return (
            <div id="PlanRequest" className="body">

				<p className="text-danger">{errorMessage}</p>
                <form className="simple-form" onSubmit={this.handleSubmit}>
                	<div className="form-group">
                		<label>Your Name*</label>
                		<input className={`form-control ${(form.fullName.valid)? 'valid' : 'invalid' }`} name="fullName" type="text" onChange={this.handleChange} required />
                        {form.fullName.error}
                	</div>
					{/*<div className="form-group">*/}
						{/*<label>Last Name*</label>*/}
						{/*<input className={`form-control ${(form.lastName.valid)? 'valid' : 'invalid' }`} name="lastName" type="text" onChange={this.handleChange} required  />*/}
                        {/*{form.lastName.error}*/}
					{/*</div>*/}
                	<div className="form-group">
                		<label>Company Name*</label>
                		<input className={`form-control ${(form.company.valid)? 'valid' : 'invalid' }`} name="companyName" type="text" onChange={this.handleChange} required  />
                        {form.company.error}
                	</div>
                	<div className="form-group">
                		<label>Website*</label>
                		<input className={`form-control ${(form.website.valid)? 'valid' : 'invalid' }`} name="website" type="text" onChange={this.handleChange} required />
                        {form.website.error}
                	</div>
                	<div className="form-group">
                		<label>Your Email*</label>
                		<input className={`form-control ${(form.email.valid)? 'valid' : 'invalid' }`} name="email" type="email" onChange={this.handleChange} required />
                        {form.email.error}
                	</div>
                	<div className="form-group">
                		<label>Your Mobile Phone* (Access code sent via text)</label>
                		<input className={`form-control ${(form.mobilePhone.valid)? 'valid' : 'invalid' }`} name="mobilePhone" type="tel" onChange={this.handleChange} required />
                        {form.mobilePhone.error}
                	</div>

                	<button type="submit" className={`btn btn-primary clearfix ${form.valid}`}>Start Building Plans & Pricing</button>
                	<p className="bottom20"><small className="clearfix top10 credit-text">No credit card required</small></p>

                	<p><Link className="clearfix link" to={{ pathname: '/login', search: this.props.location.search}}>Already have an access code? <i className="fa fa-angle-right"></i></Link></p>

            	</form>
                {fireRedirect && (
					<Redirect to={'/login'}/>
                )}

            </div>
        )
    }
}

export default PlanRequest
