import React from 'react'
import './ModalLetterVideo.css'

function ModalLetterVideo(props) {

    if (props.isOpen) {
        return (
            <div className="modal-video-overlay">
                <div className="modal-video-content">
                    <div className="modal-header">
                        <i onClick={props.handleCloseModal} className="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div className="modal-video">
                        <iframe title="modal-video" width="560" height="315" src={`https://www.youtube.com/embed/${props.videoId}?rel=0&autoplay=1`} frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        )
    } else {
        return (null)
    }

}


export default ModalLetterVideo
