import React from 'react'

function MCDescription(props){

    let content = "I'm a Marketing Consultant and your point of contact here at Marketing360®. I will be in touch to help you get started and answer any questions you may have. I can help customize your plan to best fit your budget & goals."

    if(props.type === 'short'){
      content = "Your point of contact here at Marketing360®"
    }

    return(
      <h4>{content}</h4>
    )

}

export default MCDescription
