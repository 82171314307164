import React, { Component } from "react";
import ModalLetterVideo from "../modals/ModalLetterVideo";

import { formatPhoneNumber } from "../helpers/Funcs";

const pageInfo = {
  ca: "ca text",
  com: "usa text",
};

function getRegion(currentPage) {
  switch (currentPage) {
    case "planbuilder.marketing360.ca":
    case "planbuilder-ca.madwire.network":
      return "ca";

    case "planbuilder.marketing360.com":
    case "planbuilder.madwire.network":
      return "com";

    default:
      throw new Error("Unable to determine region");
  }
}

class LetterContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVideo: "",
      modalIsOpen: false,
      pageKey: null,
    };
  }
  componentDidMount() {
    let currentPage = window.location.hostname;
    this.detectPageURL(currentPage);
  }

  handleOpenModal = (videoId) => {
    this.setState(() => ({
      selectedVideo: videoId,
      modalIsOpen: true,
    }));
  };

  handleCloseModal = () => {
    this.setState(() => ({
      modalIsOpen: false,
    }));
  };
  detectPageURL = (currentPage) => {
    const pageKey = getRegion(currentPage);

    this.setState(() => ({
      pageURL: currentPage,
      pageKey,
    }));
    console.log(currentPage);
  };

  render() {
    let proNoun = "We";
    let proNoun2 = "us";
    if (this.props.marketingConsultant.mcAssigned) {
      proNoun = "I";
      proNoun2 = "me";
    }

    let leadName = this.props.user.fullName;
    leadName = leadName.split(" ");
    leadName = leadName[0];
    if (leadName === "defaultFullName") {
      leadName = "";
    } else {
      leadName = " " + leadName;
    }
    let phone = "833-277-1327";

    let calendy = null;
    let signature = (
      <img
        src="https://d36evp5uoqrlgl.cloudfront.net/logo-marketing360-alt.png"
        alt="logo"
      />
    );
    if (this.props.marketingConsultant.mcAssigned) {
      signature = this.props.marketingConsultant.fullname;
      if (this.props.marketingConsultant.calendyurl) {
        calendy = (
          <div>
            <p>Or, schedule an appointment with me here:</p>
            <p>
              <a
                href={this.props.marketingConsultant.calendyurl}
                className="btn btn-sm btn-primary"
              >
                Schedule Now
              </a>
            </p>
          </div>
        );
      }
      if (this.props.marketingConsultant.phonenumber) {
        phone = formatPhoneNumber(this.props.marketingConsultant.phonenumber);
      }
    }

    let phoneLink = `tel:${phone}`;

    const context = {
      proNoun,
      proNoun2,
      calendy,
      leadName,
      signature,
      phone,
      phoneLink,
    };

    switch (this.state.pageKey) {
      case "ca":
        return this.renderCa(context);

      case "com":
        return this.renderUsa(context);

      default:
        return <div>Loading...</div>;
    }
  }

  renderCa({
    proNoun,
    proNoun2,
    calendy,
    leadName,
    signature,
    phone,
    phoneLink,
  }) {
    return (
      <div
        className={`container-letter ${
          this.state.pageKey && this.state.pageKey
        } ${
          this.state.pageKey ? pageInfo[this.state.pageKey] : "some ca text"
        }`}
      >
        <div className="letter">
          <p>Hi{leadName}!</p>

          <p>
            Thank you for requesting plans and pricing. {proNoun} appreciate
            the opportunity to earn your business. See below for details on our
            different plans and pricing.
          </p>

          <p className="top30">
            <strong>
              <em> How does pricing work for this plan?</em>
            </strong>
          </p>

          <p className="top30">
            <small>
              The pricing for this plan is based on your marketing goals/needs, the size of your marketing area, the number of products and services you offer and more. With marketing, one size does not fit all. For example, a business targeting the entire country will need a larger advertising budget than a business targeting only Vancouver, B.C. Similarly, a business targeting only Kenora, Ontario, will need a much smaller advertising budget than one targeting Toronto. Therefore, we will be in touch with you to better understand your marketing needs and provide a budget that will best help you achieve your goals. Don’t worry, we only need about 15 minutes of your time to get what we need and provide you some suggestions on budget.
            </small>
          </p>

          <p className="top30">
            <small>
              We will also analyze your competitors and get you information on what they are doing and spending on marketing. Whether you decide to work with us or not, this will be valuable information to have. Lastly, if your budget is small, don’t worry. We can work with almost any size budget and will help formulate a plan that makes the best use of the budget you have. Essentially, give us a little bit of your budget, and let us earn the rest!
            </small>
          </p>

          <h3 className="letter__title">
            Marketing 360™ Powered By Star Metroland Media Base Platform{" "}
            <span className="letter__badge">Included in all Plans</span>
          </h3>

          <p>
            <strong>
              <em>Talent and technology you need to succeed</em>
            </strong>
          </p>

          <p>
            Our Marketing 360™ Powered By Star Metroland Media Base Platform is our all-in-one marketing platform for small business. It includes everything you need to compete and win online. One thing that is unique about the Marketing 360™ Powered By Star Metroland Media platform is that it’s a combination of both talent and technology. Meaning, you not only get the best tools you need to market and grow your business online, but you can also work with a dedicated marketing team who does everything for you, so you can focus on running your business while we focus on driving your business. What's included:
          </p>

          <ul className="letter__list letter__list__ca">
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--crm"></div>
                <p>
                  <strong>CRM</strong>
                </p>
                <p>
                  Stay organized as your business grows with a fully customizable CRM that includes unlimited users, unlimited contacts, free training, setup and dedicated support. Already using a CRM you like? No problem! We can set up Marketing 360™ Powered By Star Metroland Media to work with any CRM.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--em3"></div>
                <p>
                  <strong>Email Marketing</strong>
                </p>
                <p>
                  Grow your business with email marketing and the power of automation! Design, send and automate email campaigns in minutes. Send offers to increase sales, get more reviews, send newsletters and more. Do it yourself or have us do it for you! Send up to 2,500 emails per month, free!
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--sms"></div>
                <p>
                  <strong>SMS Marketing</strong>
                </p>
                <p>
                  Boost sales with text message marketing! Create and send text message campaigns in minutes. Send offers to increase sales, time sensitive promotions and more. Do it yourself or have us do it for you! Send up to 1,000 text messages per month, free!
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--lla"></div>
                <p>
                  <strong>Listings</strong>
                </p>
                <p>
                  Get your business listed on some of the most popular local sites, directories and apps with ease. Manage and monitor your listings and make mass updates in minutes. Do it yourself through the Marketing 360™ Powered By Star Metroland Media platform or have us do it for you anytime for free.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--rep"></div>
                <p>
                  <strong>Reputation</strong>
                </p>
                <p>
                  Protect, manage and build your brand’s online reputation with ease. Monitor your online reviews across all major review sites, and respond to reviews on Google, Facebook and Top Rated Local™ from one place.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--social"></div>
                <p>
                  <strong>Social</strong>
                </p>
                <p>
                  Build your presence on social media, post content and more. Create social posts in bulk and post to multiple social profiles from one place. Access helpful social tools, analytics and even your own Social Media Manager.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--content"></div>
                <p>
                  <strong>Content</strong>
                </p>
                <p>
                  Get your business ranking higher with beautifully crafted content marketing and SEO. Track and improve your organic rankings on Google for your best keywords with ease. A content marketing and SEO expert can even optimize your website, write content, create videos and more to increase your business's ranking.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--ads"></div>
                <p>
                  <strong>Ads</strong>
                </p>
                <p>
                  Get ahead of your competitors on all ad channels by getting in front of your ideal audience to generate sales. Run ads across Google, Facebook, Instagram and more!
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--intelligence"></div>
                <p>
                  <strong>Intelligence</strong>
                </p>
                <p>
                  Get all the analytics, reports, tracking and other important tools you need to win. The Marketing 360™️ Star Metroland Media base platform includes all the in-depth reporting, call tracking and other tools you need to make the right decisions at the right time to grow your business and capture market share.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--website"></div>
                <p>
                  <strong>Websites</strong>
                </p>
                <p>
                  Get a beautifully designed website or online store that drives results. Work with a project manager and their team of talented designers to create the design of your dreams. Already have a website you like? No problem! We can set up Marketing 360™️ Star Metroland Media to work with any website platform and help optimize your current design to drive more results.
                </p>
              </div>
            </li>
          </ul>

          <h3 className="letter__title">
            Add-on Marketing Programs to Fuel Your Brand&reg;{" "}
            <span className="letter__badge">Included Based on Needs</span>
          </h3>

          <p>
            Get everything included in the Marketing 360™ Powered By Star Metroland Media Base Platform above, plus any or all of the marketing programs and services you desire below to fuel your brand and grow your business:
          </p>

          <ul className="letter__list letter__list__ca">
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--me"></div>
                <p>
                  <strong>Dedicated Marketing Team</strong>
                </p>
                <p>
                  Get your own marketing team of specialists who do everything for your business. It's like having your own in-house marketing team! Call, email or text them whenever you’d like. Their goal is to grow your business.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--smm"></div>
                <p>
                  <strong>Social Media Management</strong>
                </p>
                <p>
                  Improve your brand’s presence on social media and capture more market share. Get your own dedicated social media team! They'll help you get more followers, post share-worthy content, monitor comments and more so your brand stays active and engaged on social media.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--content"></div>
                <p>
                  <strong>Content Marketing and SEO</strong>
                </p>
                <p>
                  Rank higher for popular searches on Google and Bing that drive you sales. An SEO/content Marketing expert will optimize your website, write blogs, create videos and more on an ongoing basis so Google ranks you high for the popular search terms that drive you sales.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--ads"></div>
                <p>
                  <strong>Multi-Channel Advertising</strong>
                </p>
                <p>
                  Advertise to past visitors and stay top of mind with the power of remarketing. Hate it when people view your website or ad without completing the desired action? Retargeting brings them back by serving personalized ads across the web and social media so you can win more business.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--creative"></div>
                <p>
                  <strong>On-Demand Creative Services</strong>
                </p>
                <p>
                  Get your own on-demand creative team and access simple tools to ensure your brand stays beautiful.
                </p>
              </div>
            </li>
            <li>
              <div>
                <div className="letter-icon-ca letter-icon--video"></div>
                <p>
                  <strong>On-Demand Video Services</strong>
                </p>
                <p>
                  Take advantage of professional video services backed by experienced professionals that have worked with hundreds of clients across every industry.
                </p>
              </div>
            </li>
          </ul>

          <p className="top30 bottom20">
            <strong>
              <em>We look forward to speaking with you!</em>
            </strong>
          </p>

          <p className="bottom0">
            Call {proNoun2} now to learn more at{" "}
            <a href="tel:844-567-6440">844-567-6440</a>{" "}
          </p>
          <p>
            <em>
              <small>
                *Open: Monday-Friday from 9am-6pm ET, 8am-5pm CT, 7am-4pm MT,
                6am-3pm PT
              </small>
            </em>
          </p>
          {calendy}

          <p>Thank you, and {proNoun} look forward to working with you,</p>

          <p>
            <span className="letter__signature-ca">
              Marketing 360™ Powered By Star Metroland Media Team
            </span>
          </p>
        </div>
      </div>
    );
  }

  renderUsa({
    proNoun,
    proNoun2,
    calendy,
    leadName,
    signature,
    phone,
    phoneLink,
  }) {
    return (
      <div
        className={`container-letter ${
          this.state.pageKey && this.state.pageKey
        } ${
          this.state.pageKey ? pageInfo[this.state.pageKey] : "some com text"
        }`}
      >
        <div className="letter">
          <ModalLetterVideo
            isOpen={this.state.modalIsOpen}
            videoId={this.state.selectedVideo}
            handleCloseModal={this.handleCloseModal}
          />

          <p>Hi{leadName}!</p>

          <p>
            Thank you for requesting plans &amp; pricing. {proNoun} appreciate
            the opportunity to earn your business. See below for details on our
            different plans &amp; pricing.
          </p>

          <h3 className="letter__title">
            Marketing 360&reg; Base Platform{" "}
            <span className="letter__badge">Included in all Plans</span>
          </h3>

          <p>
            <strong>
              <em>Talent &amp; technology you need to succeed</em>
            </strong>
          </p>

          <p>
            Our Marketing 360&reg; platform is the #1 marketing platform&reg;
            for small business. It includes everything you need to compete and
            win online. One thing that is unique about the Marketing 360&reg;
            platform is it’s a combination of both talent &amp; technology.
            Meaning, you not only get the best tools you need to market and grow
            your business online, but you also get a dedicated marketing team
            who does everything for you, so you can focus on running your
            business while we focus on driving you business. What's included:
          </p>

          <ul className="letter__list letter__list__usa">
            <li>
              <div>
                <p>
                  <strong>Payments</strong>
                </p>
                <p>
                  We have everything you need to accept and manage payments,
                  invoices, inventory, loyalty programs and more online,
                  in-store or on the go! Whether it’s getting setup with payment
                  processing, a mobile credit card swiper, an in-store
                  point-of-sale system or getting approved for funding, we have
                  your covered! Enjoy unmatched customer service &amp; free
                  support. Plus, our rates are among the lowest you’ll find. In
                  addition to that, by using Marketing 360™️ Star Metroland
                  Media Payments, you can leverage your payment data to drive
                  better marketing decisions and automation to capture more
                  sales!
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Video:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("kXWmInAYezY");
                      }}
                    >
                      Overview
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>CRM</strong>
                </p>
                <p>
                  Stay organized as your business grows with a fully
                  customizable CRM that includes unlimited contacts, free
                  training, setup &amp; dedicated support. Already using a CRM
                  and you like? No problem! We can set up Marketing 360&reg; to
                  work with any CRM.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("JDqaZy96ZuE");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("7D_RY5DBki8");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Email Marketing</strong>
                </p>
                <p>
                  Grow your business with email marketing & the power of
                  automation! Design, send & automate email campaigns in
                  minutes. Send offers to increase sales, get more reviews, send
                  newsletters and more. Do it yourself or have us do it for you!
                  Send up to 2,500 emails per month free, then it’s only $0.004
                  per email send after that (which comes out to only $10 for
                  every additional 2,500 emails you send per month).
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("N8K6q9i8kHY");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("AAWMqVEv1W4");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>SMS Marketing</strong>
                </p>
                <p>
                  Boost sales with text message marketing! Create &amp; send
                  text message campaigns in minutes. Send offers to increase
                  sales, time sensitive promotions and more. Do it yourself or
                  have us do it for you! Send up to 1,000 text messages per
                  month free, then it’s only $0.01 per text after that (which
                  comes out to only $10 for every additional 1,000 text messages
                  you send per month).
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("YRDUHe4aCjM");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("kRImQGJuqGg");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Listings</strong>
                </p>
                <p>
                  Get your business listed on over 50 of the most popular local
                  sites, directories &amp; apps with ease. Manage &amp; monitor
                  your listings &amp; make mass updates in minutes. Do it
                  yourself through the Marketing 360® platform or have us do it
                  for you anytime for free.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("o_AqXuumEBQ");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("XissefnS9vI");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Reputation</strong>
                </p>
                <p>
                  Protect, manage &amp; build your brand’s online reputation
                  with ease. Monitor your online reviews across all major review
                  sites and respond to reviews on Google, Facebook, and Top
                  Rated Local® in one place.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("AgNbtKGsgc0");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("wh5LfjCUo3w");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Social</strong>
                </p>
                <p>
                  Build your presence on social media, post content, monitor
                  comments and more. Create social posts in bulk and post to all
                  your social profiles from one place. Access helpful social
                  tools, analytics, and even your own Social Media Manager.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("LH7s7zvIpC8");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("jzKg4co5myo");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Content</strong>
                </p>
                <p>
                  Get your business ranking higher with beautifully crafted
                  content marketing &amp; SEO. Track and improve your organic
                  rankings on Google for your best keywords with ease. A Content
                  Marketing and SEO expert can even optimize your website, write
                  content, create videos and more to increase your business'
                  ranking.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("YlsV_2uAdFU");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("XNxOia1-sLI");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Ads</strong>
                </p>
                <p>
                  Get ahead of your competitors on all ad channels in front of
                  your ideal audience to generate sales. Run ads across Google,
                  Facebook, Instagram, and more!
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("_kz95L1L8Ok");
                      }}
                    >
                      Retargeting Ads
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("Zc89rMRLsDg");
                      }}
                    >
                      Search Ads
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("OdGitPXg2uQ");
                      }}
                    >
                      Social Ads
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Intelligence</strong>
                </p>
                <p>
                  Get all the analytics, reports, tracking &amp; other important
                  tools you need to win. The Marketing 360® Base Platform
                  includes all the in-depth reporting, call tracking, and other
                  tools you need to make the right decisions at the right time
                  to grow your business and capture market-share.{" "}
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("RfMzvPQhusE");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("4QeK5dKflLc");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Websites 360®</strong>
                </p>
                <p>
                  Get a beautifully designed website or online store that drives
                  results. Work with a project manager and their team of
                  talented designers to create the design of your dreams.
                  Already have a website you like? No problem! We can set up
                  Marketing 360&reg; to work with any website platform and help
                  optimize your current design to drive more results.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("0AJ4loakv8M");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("3OZDYX1CZok");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Top Rated Local&reg;</strong>
                </p>
                <p>
                  Manage &amp; monitor all your brand’s online reviews from one
                  platform with ease. Top Rated Local&reg; is a powerful
                  platform that makes it easy for you to capture &amp; monitor
                  reviews as well as build a strong online reputation across all
                  the trusted review sites relevant to your business.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("TWvsM7pQGO8");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("VUSZUoSvnM4");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
          </ul>

          <h3 className="letter__title">
            Add-on Marketing Programs to Fuel Your Brand&reg;{" "}
            <span className="letter__badge">Included Based on Needs</span>
          </h3>

          <p>
            Get everything included in the Marketing 360&reg; Base Platform
            above, plus any or all of the marketing programs &amp; services you
            desire below to fuel your brand and grow your business:
          </p>

          <ul className="letter__list letter__list__usa">
            <li>
              <div>
                <p>
                  <strong>Dedicated Marketing Team</strong>
                </p>
                <p>
                  Get your own marketing team of specialists who do everything
                  for your business. It's like having your own in-house
                  marketing team! Call, email or text them whenever you’d like.
                  Their goal is to grow your business.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("oYkQ6e9utHY");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("7GF9G-Jb1RY");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Reputation Management</strong>
                </p>
                <p>
                  Protect, manage &amp; build your brand’s online reputation
                  with ease. Monitor your online reviews across all major review
                  sites and respond to reviews on Google, Facebook, and Top
                  Rated Local® in one place.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("AgNbtKGsgc0");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("wh5LfjCUo3w");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Social Media Management</strong>
                </p>
                <p>
                  Improve your brand’s presence on social media and capture more
                  market-share. Get your own dedicated social media team!
                  They'll help you get more followers, post share-worthy
                  content, monitor comments &amp; more so your brand is active
                  &amp; engaged on social media.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("LH7s7zvIpC8");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("jzKg4co5myo");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Content Marketing &amp; SEO (Content Credits)</strong>
                </p>
                <p>
                  Rank higher for popular searches on Google &amp; Bing that
                  drive you sales. An SEO/Content Marketing expert will optimize
                  your website, write blogs, create videos and more on an
                  ongoing basis so Google ranks you high for the popular search
                  terms that drive you sales.
                </p>
                <p>
                  <em>
                    {/* <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "} */}
                    Watch Videos:{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("YlsV_2uAdFU");
                      }}
                    >
                      Overview
                    </a>{" "}
                    |{" "}
                    <a
                      onClick={() => {
                        this.handleOpenModal("XNxOia1-sLI");
                      }}
                    >
                      How it Works
                    </a>
                  </em>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>Multi-Channel Advertising (Ad Credits)</strong>
                </p>
                <p>
                  Grow your business by advertising your brand across search,
                  social, and more! An advertising expert will write, design,
                  manage &amp; optimize your ads so they run on ideal positions
                  on every ad channel, helping you capture market share.
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>
                    On-Demand Creative Services (Creative Credits)
                  </strong>
                </p>
                <p>
                  Get your own on-demand creative team and access to simple
                  tools to ensure your brand stays beautiful.
                </p>
              </div>
            </li>
            <li>
              <div>
                <p>
                  <strong>On-Demand Video Services (Video Credits)</strong>
                </p>
                <p>
                  Professional video services backed by experienced
                  professionals that have worked with hundreds of clients across
                  every industry.{" "}
                </p>
              </div>
            </li>
          </ul>

          <p>
            <strong>
              <em>
                {/* <i className="fa fa-long-arrow-right" aria-hidden="true"></i>{" "} */}
                How does pricing work for this plan?
              </em>
            </strong>
          </p>

          <p>
            The pricing for this plan is based on your marketing goals/needs,
            size of your marketing area, number of products &amp; services you
            offer and more. With marketing, one size does not fit all. For
            example, a business targeting the entire United States will need a
            larger advertising budget than a business targeting only New York.
            Similarly, a business targeting only Wichita, Kansas will need a
            much smaller advertising budget than one targeting Chicago.
            Therefore, {proNoun} will be in touch with you to better understand
            your marketing needs and provide a budget that will best help you
            achieve your goals. Don’t worry, {proNoun} only need about 15
            minutes of your time to get what {proNoun} need and provide you some
            suggestions on budget.
          </p>
          <p className="bottom10">
            {proNoun} will also analyze your competitors and get you information
            on what they are doing and spending on marketing. Whether you decide
            to work with us or not, this will be valuable information to have.
            Lastly, if your budget is small, don’t worry. {proNoun} can work
            with almost any size budget and will help formulate a plan that
            makes the best use of the budget you have. Essentially, give us a
            little bit of your budget and let us earn the rest!
          </p>

          {/* <h3 className="letter__title">
            Marketing 360® Payments | Accept &amp; Manage Payments
          </h3> */}
          {/* <p>
            <strong>
              <em>Accept &amp; Manage Payments With Ease</em>
            </strong>
          </p> */}
          {/* <p>
            We have everything you need to accept and manage payments, invoices,
            inventory, loyalty programs and more online, in-store or on the go!
            Whether it’s getting setup with payment processing, a mobile credit
            card swiper, an in-store point-of-sale system or getting approved
            for funding, we have your covered! Enjoy unmatched customer service
            &amp; free support. Plus, our rates are among the lowest you’ll
            find. In addition to that, by using Marketing 360® payments, you can
            leverage your payment data to drive better marketing decisions and
            automation to capture more sales!
          </p>
          <p className="bottom20">
            <em>
              <i className="fa fa-arrow-right" aria-hidden="true"></i> Ask to
              speak to one of our Payment Consultants to learn more
            </em>
          </p> */}
          <p className="top30 bottom20">
            <strong>
              <em>I look forward to speaking with you!</em>
            </strong>
          </p>

          <p className="bottom0">
            Call {proNoun2} now at <a href={phoneLink}>{phone}</a>{" "}
          </p>
          <p>
            <em>
              <small>
                *Open: Monday-Friday from 9am-6pm ET, 8am-5pm CT, 7am-4pm MT,
                6am-3pm PT
              </small>
            </em>
          </p>
          {calendy}

          <p>Thank you &amp; {proNoun} look forward to working with you,</p>

          <p>
            <span className="letter__signature">{signature}</span>
          </p>
        </div>
      </div>
    );
  }
}

export default LetterContent;
