import React from 'react'
import './MCLetterComponent.css'
import MCDescription from './subComponents/MCDescription.js'
import { formatPhoneNumber } from './helpers/Funcs'

function MCLetterComponent(props) {

  let mcTitle = `Hi`
  let mcPhone = `${props.marketingConsultant.phonenumber}`
  let mcPhoneLink = `tel:${mcPhone}`
  let phoneContent = null
  let emailContent = null
  let calendyContent = null
  let mcImageUrl = `${props.marketingConsultant.imageurl}`
  let formattedPhoneNumber = ''
  let mcEmail = `${props.marketingConsultant.email}`
  let emailLink = `mailto:${mcEmail}`

  if (props.marketingConsultant.mcAssigned) {
    mcTitle = `Hi, I'm ${props.marketingConsultant.firstname}`
  }

  if (props.marketingConsultant.phonenumber) {
    formattedPhoneNumber = `${formatPhoneNumber(props.marketingConsultant.phonenumber)}`
    phoneContent = <div>
      <p className="margin-bottom-0">Call me at:</p>
      <p className="margin-bottom-0"><a className="phone-link contact-link" href={mcPhoneLink}>{formattedPhoneNumber}</a></p>
      <small className="timeZone">*Open: Monday-Friday, 9am-6pm ET, 8am-5pm CT, 7am-4pm MT, 6am-3pm PT</small>
    </div>
  } else {
    formattedPhoneNumber = '833-277-1327'
  }

  if (props.marketingConsultant.email) {
    emailContent = <div>
      <p className="margin-bottom-0">Email me at:</p>
      <p><a className="email-link contact-link" href={emailLink}>{mcEmail}</a></p>
    </div>
  }

  if (props.marketingConsultant.calendyurl) {
    calendyContent = <div>
      <p>Or, schedule a time here:</p>
      <p><a className="btn btn-primary" href={props.marketingConsultant.calendyurl}>schedule meeting</a></p>
    </div>
  }

  return (
    <div className="mc-profile">
      <img className="mc-image" src={mcImageUrl} alt="mc" />
      <h3 className="mc-header">{mcTitle}</h3>
      <h4>Your point of contact here at Marketing 360&reg;</h4>
      <p>See below for detailed information on the 3 main plans we offer and their pricing.
          Don't hesitate to contact me with any questions you might have along the way.
        </p>
      {phoneContent}
      {emailContent}
      {calendyContent}
    </div>
  )

}

export default MCLetterComponent
