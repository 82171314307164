import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './LoginPrompt.css'

import CheckModalComponent from './modals/CheckModalComponent'
import PhoneModalComponent from './modals/PhoneModalComponent'

class LoginPrompt extends Component {

    constructor(props) {
        super(props)

        this.state = {
            checkModalIsActive: props.checkModalIsActive || false,
            phoneModalIsActive: false,
        }

        this.toggleCheckModal = this.toggleCheckModal.bind(this)
        this.togglePhoneModal = this.togglePhoneModal.bind(this)
    }


    toggleCheckModal(){
        this.setState({
            checkModalIsActive: !this.state.checkModalIsActive,
            phoneModalIsActive: false,
        })
    }

    togglePhoneModal(){
        this.setState({
            checkModalIsActive: false,
            phoneModalIsActive: !this.state.phoneModalIsActive,
        })
    }

    render() {

        let checkModal = null
        let phoneModal = null
        let name = null

        if(this.props.user){
            if(this.props.user.fullName !== 'defaultFullName'){
                let nameArray = this.props.user.fullName.split(" ")
                if(nameArray[0] !== null && nameArray[0] !== undefined ){
                    name = nameArray[0]
                    name = ' ' + name
                }

            }
        }

        if(this.state.checkModalIsActive){
            checkModal = <CheckModalComponent api={this.props.api} history={this.props.history} toggleModal={this.toggleCheckModal} user={this.props.user} resendText={this.props.resendText} handleChange={this.props.updateUser}/>
        }

        if(this.state.phoneModalIsActive){
            phoneModal = <PhoneModalComponent marketingConsultant={this.props.marketingConsultant} toggleModal={this.togglePhoneModal} user={this.props.user} />
        }


        return (
            <div className="LoginPrompt full-body">
                {checkModal}
                {phoneModal}
                <h1 className="title">Hi{name}!</h1>
                <p className="info bottom40">Thanks for requesting plans & pricing. You should have received a text
                message with an access code. Click the button below to enter your code.</p>
                <Link className="btn btn-primary bottom40" to={{ pathname: '/login', search: this.props.location.search}} >Enter Access Code</Link>
                <p className="more-info">Didn't get a text? <button className="text-link" onClick={this.togglePhoneModal}>give us a call</button>.</p>
            </div> 
        )
    }
}

export default withRouter(LoginPrompt)
