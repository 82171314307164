import React, { Component } from 'react'
import './PlanComponent.css'
import { withRouter } from 'react-router-dom'
import TextAreaComponent from './TextAreaComponent'
import ModalComponent from './modals/ModalComponent'
import ModalComponentVideo from './modals/ModalComponentVideo'
import CallUs from './CallUs'
import {postUpdate, getLog} from './helpers/apiHelper'


class PlanComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            infoModalIsActive: false,
            overviewModalIsActive: false,
            howItWorksModalIsActive: false,
        }

        this.toggleInfoModal = this.toggleInfoModal.bind(this)
        this.toggleOverviewModal = this.toggleOverviewModal.bind(this)
        this.toggleHowItWorksModal = this.toggleHowItWorksModal.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    componentWillReceiveProps() {
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: false,
            howItWorksModalIsActive: false
        })
    }

    handleClick(event){

        //update app state
        this.props.handlePlanUpdate(event)

        //generate log
        let logEntryString = getLog(this.props.plan[this.props.details.key], this.props.details.title)

        //build data to send to backend
        let updateData = {
          //update plan item
          [this.props.details.key]: this.props.plan[this.props.details.key],
          planTotals: this.props.plan.planTotals,
          base: this.props.plan.base
        }

        if (this.props.openFromModal) {
            this.props.toggleModal()
        } else {
            this.props.setStep(this.props.details.step + 1)
            updateData.currentStep = this.props.details.step + 1
            updateData.furthestStep = this.getFurthestStep(this.props.details.step + 1)
            this.props.history.push(this.props.details.linkTo + this.props.history.location.search)
        }

        let updateDataString = JSON.stringify(updateData)
        postUpdate(this.props.api, this.props.plan.id, updateDataString, logEntryString)

    }

    getFurthestStep(currentStep){
      if(currentStep === 20){
        return 20
      }
      if(currentStep > this.props.plan.furthestStep){
        return currentStep
      }
    }

    toggleInfoModal(){
        this.setState({
            infoModalIsActive: !this.state.infoModalIsActive,
            overviewModalIsActive: false,
            howItWorksModalIsActive: false
        })
    }

    toggleOverviewModal(){
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: !this.state.overviewModalIsActive,
            howItWorksModalIsActive: false
        })
    }

    toggleHowItWorksModal(){
        this.setState({
            infoModalIsActive: false,
            overviewModalIsActive: false,
            howItWorksModalIsActive: !this.state.howItWorksModalIsActive
        })
    }


    render() {

        let infoModal = null
        let overviewModal = null
        let howItWorksModal = null
        let optOutButton = null
        let vidRow = null
        let planBadge = null
        let planBottom = null

        if(this.state.infoModalIsActive){
            infoModal = <ModalComponent toggleModal={this.toggleInfoModal} moreInfo={this.props.details.moreInfo} />
        }
        if(this.state.overviewModalIsActive){
            overviewModal = <ModalComponentVideo toggleModal={this.toggleOverviewModal} videoUrl={this.props.details.overviewUrl} />
        }
        if(this.state.howItWorksModalIsActive){
            howItWorksModal = <ModalComponentVideo toggleModal={this.toggleHowItWorksModal} videoUrl={this.props.details.howItWorksUrl} />
        }

        let dynamicClass = `${this.props.details.key}`
        if(this.props.display){
          dynamicClass += " " + this.props.display
        }

        if(this.props.details.secondaryButtonText.length !== 0){
            optOutButton = <button className="btn btn-secondary" name={this.props.details.key} value="false" onClick={this.handleClick}>{this.props.details.secondaryButtonText}</button>
        }

        if(this.props.details.overviewUrl && this.props.details.howItWorksUrl){
          vidRow = <div className="vid-row">
                      <button className="btn btn-video" onClick={this.toggleOverviewModal}>
                          <img className="overview-icon" src={this.props.details.overViewIcon} alt="video-icon"/>
                          <span>Overview</span>
                      </button>
                      <button className="btn btn-video" onClick={this.toggleHowItWorksModal}>
                          <img className="how-icon" src={this.props.details.howItWorksIcon} alt="video-icon"/>
                          <span>How It Works</span>
                      </button>
                  </div>
        }

        if(this.props.display !== 'simple'){
          planBadge = <div className={"plan-badge " + dynamicClass}></div>
          planBottom = <div className="plan-bottom">
                        {vidRow}
                         {<CallUs marketingConsultant={this.props.marketingConsultant} user={this.props.user}/>}
                      </div>
        }

        return (
            <div className={"plan-component-wrapper " + dynamicClass}>
                <div id="plan-component" className={"PlanComponent component body " + dynamicClass}>
                    {infoModal}
                    {overviewModal}
                    {howItWorksModal}
                    <div className="plan-top">
                        <h1 className="title title-blank"><img className="product-icon" src={this.props.details.productIcon} alt="product-icon"/> {this.props.details.title}</h1>

                        <TextAreaComponent
                            variable={this.props.variable}
                            textType="line1"
                            textContent={this.props.details.line1}
                        />

                        <TextAreaComponent
                            variable={this.props.variable}
                            textType="description"
                            textContent={this.props.details.description}
                        />
                        <TextAreaComponent
                            variable={this.props.variable}
                            textType="line2 text-capitalize"
                            textContent={this.props.details.line2}
                        />

                        <div className="btn-row">
                            <button className="btn btn-primary" name={this.props.details.key} value="true" onClick={this.handleClick}>{this.props.details.primaryButtonText}</button>
                            {optOutButton}
                        </div>

                    </div>
                    {planBadge}
                    {planBottom}
                </div>
            </div>
        )
    }
}


export default withRouter(PlanComponent)
