import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import './Login.css'

import ResendModalComponent from './modals/ResendModalComponent'
import PhoneModalComponent from './modals/PhoneModalComponent'

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            resendModalIsActive: false,
            phoneModalIsActive: props.phoneModalIsActive || false,
            errorMessage: null,
            fireRedirect: false
        }

        this.toggleResendModal = this.toggleResendModal.bind(this)
        this.togglePhoneModal = this.togglePhoneModal.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeOne = this.handleChangeOne.bind(this)
        this.handleChangeTwo = this.handleChangeTwo.bind(this)
    }


    handleChangeOne(event) {
        this.props.updatePlanId(event)
        if (event.target.value.length === 4) {
            this.refs.planIdTwo.focus()
        }
    }
    handleChangeTwo(event) {
        this.props.updatePlanId(event, 'partTwo')
    }

    handleSubmit(e){

        e.preventDefault()

        let that = this

        this.props.setStateWithPlanId(this.props.planDetails.id, function(err){
            if(err){
                that.setState({ errorMessage: 'We could not find that plan. Please try again' })
            } else{
                that.setState({ fireRedirect: true })
            }
        })

    }

    toggleResendModal(){
        this.setState({
            resendModalIsActive: !this.state.resendModalIsActive,
            phoneModalIsActive: false,
        })
    }

    togglePhoneModal(){
        this.setState({
            resendModalIsActive: false,
            phoneModalIsActive: !this.state.phoneModalIsActive,
        })
    }

    render() {

        let {errorMessage} = this.state
        let resendModal = null
        let phoneModal = null
        const { fireRedirect } = this.state

        if(this.state.resendModalIsActive){
            resendModal = <ResendModalComponent api={this.props.api} history={this.props.history} toggleModal={this.toggleResendModal} resendText={this.props.resendText} handleChange={this.props.updateUser} user={this.props.user} />
        }

        if(this.state.phoneModalIsActive){
            phoneModal = <PhoneModalComponent marketingConsultant={this.props.marketingConsultant} toggleModal={this.togglePhoneModal} user={this.props.user} />
        }


        return (
            <div className="Login full-body">
                <p className="text-danger">{errorMessage}</p>
                {resendModal}
                {phoneModal}
                <h1 className="title">Plans &amp; Pricing</h1>
                <p className="info bottom20">Enter the access code we sent you via text message to get started.</p>
                <div className="box-color container-form">
                    <p>Access Code:</p>
                    <div className="form-group login-group">
                        <form onSubmit={this.handleSubmit}>

                            <input className="form-control text-center" type="text" maxLength="4" onChange={this.handleChangeOne} />
                            <span className="login-group-dash">-</span>
                            <input className="form-control text-center" type="text" maxLength="4" onChange={this.handleChangeTwo} ref="planIdTwo" />
                            <button type="submit" className="btn btn-primary">Go</button>

                        </form>
                    </div>
                </div>
                <div className="text-center">
                    <p className="bottom-link top40"><button  className="text-link" onClick={this.toggleResendModal}>Resend Text</button></p>
                    <p className="bottom-link">Problems? <button className="text-link" onClick={this.togglePhoneModal}>Call us</button></p>
                </div>
                {fireRedirect && (
                    <Redirect to={{pathname:'/plans', search: `?plan=${this.props.planDetails.id}`}} />
                )}
            </div>
        )
    }
}

export default Login
