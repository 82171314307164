import React from 'react'
import './ModalComponentPlan.css'

import PlanComponent from '../PlanComponent'

function ModalComponentPlan(props) {
    return(
        <div className="modal-plan modal-overlay">
            <div className="modal-content">

                <div className="modal-plan-container">
                    <button className="modal-close btn btn-link btn-link-white" onClick={props.toggleModal}><i className="fa fa-times-circle-o" aria-hidden="true"></i></button>
                    <PlanComponent
                        variable={props.plan.leads}
                        details={props.details}
                        handlePlanUpdate={props.handlePlanUpdate}
                        openFromModal={true}
                        toggleModal={props.toggleModal}
                        setStep={props.setStep}
                        plan={props.plan}
                        api={props.api}
                    />
                </div>
            </div>
        </div>
    )
}

export default ModalComponentPlan
