//takes a v1 plan object and the current state blank plan object, and returns a v2 plan object
export function parseV1PlanObject(currentBlankState, v1Data) {

  let newData = currentBlankState

  console.log('current blank plan state: ', currentBlankState)
  console.log('parsing v1 data: ', v1Data)

  if(v1Data.id){
    newData.id = v1Data.id
  }


  if(v1Data.planData){

    let v1DataObject = JSON.parse(v1Data.planData)

    if(v1DataObject.planIdOne){
      newData.planIdOne = v1DataObject.planIdOne
    }
    if(v1DataObject.planIdTwo){
      newData.planIdTwo = v1DataObject.planIdOne
    }

    if(v1DataObject.currentStep){
      newData.currentStep = v1DataObject.currentStep
    }
    if(v1DataObject.furthestStep){
      newData.furthestStep = v1DataObject.furthestStep
    }
    if (v1DataObject.crm.included === 'true') {
      newData.crm = true
    }
    if (v1DataObject.base.included === 'true') {
      newData.base = true
    }
    if (v1DataObject.me.included === 'true') {
      newData.me = true
    }
    if (v1DataObject.creative.included === 'true') {
      newData.creative = true
    }
    if (v1DataObject.uxi.included === 'true') {
      newData.uxi = true
    }
    if (v1DataObject.em3.included === 'true') {
      newData.em3 = true
    }
    if (v1DataObject.sms.included === 'true') {
      newData.sms = true
    }
    if (v1DataObject.lla.included === 'true') {
      newData.lla = true
    }
    if (v1DataObject.analytics.included === 'true') {
      newData.analytics = true
    }
    if (v1DataObject.trl.included === 'true') {
      newData.trl = true
    }
    if (v1DataObject.nla.included === 'true') {
      newData.nla = true
    }
    if (v1DataObject.smm.included === 'true') {
      newData.smm = true
    }
    if (v1DataObject.sta.included === 'true') {
      newData.sta = true
    }
    if (v1DataObject.tpa.included === 'true') {
      newData.tpa = true
    }
    if (v1DataObject.ret.included === 'true') {
      newData.nla = true
    }
    if(v1DataObject.businessCategory){
      newData.businessCategory = v1DataObject.businessCategory
    }
    if(v1DataObject.employeeNumber){
      newData.employeeNumber = v1DataObject.employeeNumber
    }
    if(v1DataObject.yearsInBusiness){
      newData.yearsInBusiness = v1DataObject.yearsInBusiness
    }
    if(v1DataObject.marketingArea){
      newData.marketingArea = v1DataObject.marketingArea
    }
    if(v1DataObject.customPricingAvailable){
      newData.customPricingAvailable = v1DataObject.customPricingAvailable
    }
    if(v1DataObject.planTotals){
      newData.planTotals = v1DataObject.planTotals
    }
    if(v1DataObject.hasMarketingFuel){
      newData.hasMarketingFuel = v1DataObject.hasMarketingFuel
    }
    if(v1DataObject.leads){
      newData.leads = v1DataObject.leads
    }
    if(v1DataObject.marketingGoals){
      newData.marketingGoals = v1DataObject.marketingGoals
    }
  }

  console.log('newData: ', newData)

  return newData

}
